import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BusinessService } from '../../../_services/business.service';
import { Business } from '../../../_models/business.model';

@Injectable({
  providedIn: 'root',
})
export class BusinessesCurrentForceResolver implements Resolve<Business> {

  constructor(private businessService: BusinessService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Business> {
    return this.businessService.getCurrent(true);
  }

}
