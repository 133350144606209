import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'puntos-add-email-component',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss'],
})
export class AddEmailComponent implements OnInit {
  @Input() status: string;
  @Input() title: string = 'Asociar un email al usuario';
  acceptButtonText = 'Asignar';

  @Input() acceptButtonStatus = 'primary';
  @Input() cancelButtonText = 'Cancelar';
  @Input() cancelButtonStatus = 'basic';
  @Input() client: Client;
  @Input() useDniAsPassword: boolean = true;
  @Input() askName: boolean = false;

  form: FormGroup;
  submitted = false;

  constructor(private ref: NbDialogRef<AddEmailComponent>, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    const group: any = {
      email: ['', [Validators.required, Validators.email]],
    };
    if (this.useDniAsPassword) {
      group.dni = [this.client && this.client.user.dni ? this.client.user.dni : '', [Validators.required, Validators.minLength(7), Validators.maxLength(11)]];
    } else {
      group.password = ['', [Validators.required]];
    }

    if (this.askName) {
      group.first_name = ['', [Validators.required]];
      group.last_name = ['', [Validators.required]];
    }
    this.form = this.formBuilder.group(group);
  }

  get controls() { return this.form.controls; }

  save() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.accept(this.form.value);
  }

  cancel() {
    this.ref.close(null);
  }

  accept(value) {
    this.ref.close(value);
  }

}
