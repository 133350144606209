import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Business } from '../../_models/business.model';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'puntos-business-money',
  templateUrl: './business-money.component.html',
  styleUrls: ['./business-money.component.scss'],
})
export class BusinessMoneyComponent implements OnInit, OnChanges {

  @Input() client: Client;
  @Input() business: Business;
  score: number;
  total: number = 0;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    if (!this.client || !this.business) return;

    this.score = this.client.score;
    this.total = this.score * this.business.money_ratio;
  }

}
