<div class="next-product-cluster">
  <p class="points">
    Te faltan
    <nb-icon icon="logo" pack="puntos"></nb-icon>
    <span>{{ missingPoints | ngxNumberWithCommas }}</span>
    para el siguiente premio
  </p>
  <nb-progress-bar
    [value]="nextProductPercentage"
    size="tiny"
    class="status-primary"
  ></nb-progress-bar>
  <nb-card>
    <div class="product">
      <div
        class="image"
        [style.background-image]="
          'url(' + client?.nextProductOffer?.product?.imageSizes['thumb'] + ')'
        "
      ></div>
      <div class="info">
        <p class="name">{{ client?.nextProductOffer?.product?.name }}</p>
        <p class="category">
          {{ client?.nextProductOffer?.product?.categories[0] }}
        </p>
      </div>
    </div>
    <div class="points-section">
      <nb-icon icon="logo" pack="puntos"></nb-icon>
      <span class="value">{{ client?.nextProductOffer?.value }}</span>
    </div>
  </nb-card>
</div>
