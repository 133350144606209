import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { MenuItem } from '../../pages/pages-menu';
import { NbDialogRef, NbMenuService } from '@nebular/theme';
import { Tag } from '../../_models/tag.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'puntos-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() menu: MenuItem[];
  @Input() tag: Tag;

  private clickEventSubscription: Subscription;

  constructor(private ref: NbDialogRef<MenuComponent>,
              private menuService: NbMenuService) {

  }

  ngOnInit() {

    this.clickEventSubscription = this.menuService
      .onItemClick()
      .subscribe((context) => {
        if (context.item.data && context.item.data.call) {
          context.item.data.call.call(context.item.data.this, context.item.data.tag);
        }
      });

  }

  close() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.clickEventSubscription.unsubscribe();
  }

}
