import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HistoryService {
    constructor(http) {
        this.http = http;
    }
    getPaginationResult(params) {
        return this.http
            .get('history', { params: params })
            .pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((event) => {
                return event.type === 'purchase' ? new Purchase(event) : new Read(event);
            });
            return new PaginationResult(pagination_result);
        }));
    }
    getProviderPaginationResult(params) {
        return this.http
            .get('history/provider', { params: params })
            .pipe(map((response) => {
            const pagination_result = response;
            return new PaginationResult(pagination_result);
        }));
    }
    get(params = {}) {
        return this.http
            .get('history', { params })
            .pipe(map((events) => events.map((event) => event.type === 'purchase' ? new Purchase(event) : new Read(event))));
    }
    cancelRead(read_id) {
        return this.http.delete(`reads/${read_id}`);
    }
}
HistoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.ɵɵinject(i1.HttpClient)); }, token: HistoryService, providedIn: "root" });
