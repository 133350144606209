<div class="register">
  <div class="go-back-header">
    <a routerLink="/auth/login" class="go-back float-left back">
      <nb-icon icon="Chevron_Left"></nb-icon> Volver
    </a>
  </div>
  
  <h1 class="margin-top-4rem" *ngIf="!businessUser" id="title">Registrate</h1>
  <h3 class="title-description">Ingresá todos tus datos para completar el registro</h3>
  
  <div class="auth-form">
    <nb-alert
      *ngIf="showMessages.error && errors?.length && !submitted"
      outline="danger"
      role="alert"
    >
      <p class="alert-title"><b>¡Upa!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
      </ul>
    </nb-alert>
  
    <nb-alert
      *ngIf="serverErrors && serverErrors.email && !submitted"
      outline="danger"
      role="alert"
    >
      <p class="alert-title"><b>¡Upa!</b></p>
      <ul class="alert-message-list">
        <li>
          Ya estás registrado en este comercio. Por favor, utilizá las mismas credenciales para
          <a
            class="text-link"
            [routerLink]="['../login', this.then ? { then: this.then } : {}]"
            >acceder</a
          >
          o presioná en "¿Olvidaste tu contraseña?" para cambiar tu contraseña.
        </li>
      </ul>
    </nb-alert>
  
    <nb-alert
      *ngIf="serverErrors && serverErrors.dni && !submitted"
      outline="danger"
      role="alert"
    >
      <p class="alert-title"><b>¡Upa!</b></p>
      <ul class="alert-message-list">
        <li>
          Ingresa con el mismo {{ dniOrCuit }} haciendo
          <a
            class="text-link"
            [routerLink]="['../login', this.then ? { then: this.then } : {}]"
            >click aqui</a
          >
        </li>
      </ul>
    </nb-alert>
  
    <nb-alert
      *ngIf="showMessages.success && messages?.length && !submitted"
      outline="success"
      role="alert"
    >
      <p class="alert-title"><b>¡Bien!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">
          {{ message }}
        </li>
      </ul>
    </nb-alert>
  
    <form
      class="custom-stepper"
      (ngSubmit)="register()"
      #form="ngForm"
      aria-labelledby="title"
      *ngIf="user !== undefined"
    >
      <nb-stepper class="custom-stepper" orientation="horizontal" linear>
        <nb-step>
          <div class="form-row my-3" *ngIf="!businessUser">
            <input
              nbInput
              [disabled]="businessUser"
              [(ngModel)]="user.first_name"
              #firstName="ngModel"
              id="input-first_name"
              name="first_name"
              placeholder="Nombre"
              fullWidth
              fieldSize="large"
              [status]="
                firstName.dirty ? (firstName.invalid ? 'danger' : 'success') : ''
              "
              required
              [minlength]="getConfigValue('forms.validation.fullName.minLength')"
              [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
              [attr.aria-invalid]="
                firstName.invalid && firstName.touched ? true : null
              "
              (keydown)="filterNumbers($event)"
            />
            <ng-container *ngIf="firstName.invalid && firstName.touched">
              <p class="caption status-danger" *ngIf="firstName.errors?.required">
                <i class="fas fa-times-circle"></i>
                Olvidaste ingresar tu nombre.
              </p>
              <p
                class="caption status-danger"
                *ngIf="firstName.errors?.minlength || firstName.errors?.maxlength"
              >
                <i class="fas fa-times-circle"></i>
                Tu nombre debe tener al menos
                {{ getConfigValue("forms.validation.fullName.minLength") }}
                caracteres.
              </p>
            </ng-container>
          </div>
  
          <div class="form-row my-3" *ngIf="!businessUser">
            <input
              nbInput
              [disabled]="businessUser"
              [(ngModel)]="user.last_name"
              #lastName="ngModel"
              id="input-lastName"
              name="lastName"
              placeholder="Apellido"
              fullWidth
              fieldSize="large"
              [status]="
                lastName.dirty ? (lastName.invalid ? 'danger' : 'success') : ''
              "
              required
              [minlength]="getConfigValue('forms.validation.fullName.minLength')"
              [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
              [attr.aria-invalid]="
                lastName.invalid && lastName.touched ? true : null
              "
              (keydown)="filterNumbers($event)"
            />
            <ng-container *ngIf="lastName.invalid && lastName.touched">
              <p class="caption status-danger" *ngIf="lastName.errors?.required">
                <i class="fas fa-times-circle"></i>
                Olvidaste ingresar tu apellido.
              </p>
              <p
                class="caption status-danger"
                *ngIf="lastName.errors?.minlength || lastName.errors?.maxlength"
              >
                <i class="fas fa-times-circle"></i>
                Tu apellido debe tener al menos
                {{ getConfigValue("forms.validation.fullName.minLength") }}
                caracteres.
              </p>
            </ng-container>
          </div>
  
          <div class="form-row my-3">
            <input
              nbInput
              placeholder="Fecha de nacimiento"
              [nbDatepicker]="datepicker"
              id="datepicker-birthday"
              name="birthday"
              [(ngModel)]="user.birthday"
              #birthday="ngModel"
              fullWidth
              [status]="
                birthday.dirty ? (birthday.invalid ? 'danger' : 'success') : ''
              "
              [attr.aria-invalid]="
                birthday.invalid && birthday.touched ? true : null
              "
            />
            <nb-datepicker #datepicker required></nb-datepicker>
          </div>
  
          <div class="form-row my-3">
            <nb-select
              nbInput
              placeholder="Genero"
              size="big"
              [(selected)]="user.gender"
              fullWidth
            >
              <nb-option value="0">Masculino</nb-option>
              <nb-option value="1">Femenino</nb-option>
              <nb-option value="2">Otro</nb-option>
            </nb-select>
          </div>
  
          <button nbButton nbStepperNext fullWidth class="puntos-button filled">
            Continuar
          </button>
        </nb-step>
  
        <nb-step>
          <div class="form-row my-3" *ngIf="!businessUser">
            <input
              nbInput
              [disabled]="user.id"
              [(ngModel)]="user.email"
              #email="ngModel"
              id="input-email"
              name="email"
              pattern=".+@.+..+"
              placeholder="Email"
              fullWidth
              fieldSize="large"
              [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : ''"
              [required]="getConfigValue('forms.validation.email.required')"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null"
              [class.is-invalid]="email.invalid"
            />
            <ng-container *ngIf="email.invalid && email.touched">
              <p class="caption status-danger" *ngIf="email.errors?.required">
                <i class="fas fa-times-circle"></i>
                Olvidaste ingresar tu email.
              </p>
              <p class="caption status-danger" *ngIf="email.errors?.pattern">
                <i class="fas fa-times-circle"></i>
                Este no parece un email válido.
              </p>
              <p class="caption status-danger" *ngIf="email.errors?.server">
                <i class="fas fa-times-circle"></i>
                Este email ya está tomado.
              </p>
            </ng-container>
          </div>
  
          <div class="form-row my-3" *ngIf="!businessUser">
            <input
              nbInput
              [disabled]="user.id"
              [(ngModel)]="user.dni"
              #dni="ngModel"
              id="input-dni"
              name="dni"
              placeholder="{{ dniOrCuit }}"
              fullWidth
              fieldSize="large"
              [status]="dni.dirty ? (dni.invalid ? 'danger' : 'success') : ''"
              [required]="getConfigValue('forms.validation.dni.required')"
              [attr.aria-invalid]="dni.invalid && dni.touched ? true : null"
              [class.is-invalid]="dni.invalid"
            />
  
            <ng-container *ngIf="dni.invalid && dni.touched">
              <p class="caption status-danger" *ngIf="dni.errors?.required">
                <i class="fas fa-times-circle"></i>
                Olvidaste ingresar tu {{ dniOrCuit }}.
              </p>
              <p class="caption status-danger" *ngIf="dni.errors?.server">
                <i class="fas fa-times-circle"></i>
                Este {{ dniOrCuit }} ya está tomado.
              </p>
            </ng-container>
          </div>
  
          <div class="form-row my-3" *ngIf="!businessUser">
            <input
              nbInput
              [disabled]="user.id"
              [(ngModel)]="user.phone"
              #phone="ngModel"
              id="input-phone"
              name="phone"
              placeholder="Teléfono"
              fullWidth
              fieldSize="large"
              [status]="phone.dirty ? (phone.invalid ? 'danger' : 'success') : ''"
              [attr.aria-invalid]="phone.invalid && phone.touched ? true : null"
              (keydown)="filterNumbers($event, true)"
            />
          </div>
  
          <button nbButton nbStepperNext fullWidth class="puntos-button filled">
            Continuar
          </button>
        </nb-step>
  
        <nb-step>
          <div class="form-row my-3">
            <input
              nbInput
              [(ngModel)]="user.password"
              #password="ngModel"
              type="password"
              id="input-password"
              name="password"
              placeholder="Contraseña"
              fullWidth
              fieldSize="large"
              autocomplete="new-password"
              [status]="
                password.dirty ? (password.invalid ? 'danger' : 'success') : ''
              "
              [required]="getConfigValue('forms.validation.password.required')"
              [minlength]="getConfigValue('forms.validation.password.minLength')"
              [maxlength]="getConfigValue('forms.validation.password.maxLength')"
              [attr.aria-invalid]="
                password.invalid && password.touched ? true : null
              "
            />
            <ng-container *ngIf="password.invalid && password.touched">
              <p class="caption status-danger" *ngIf="password.errors?.required">
                <i class="fas fa-times-circle"></i>
                Olvidaste ingresar tu contraseña.
              </p>
              <p
                class="caption status-danger"
                *ngIf="password.errors?.minlength || password.errors?.maxlength"
              >
                <i class="fas fa-times-circle"></i>
                La contraseña debe tener más de
                {{ getConfigValue("forms.validation.password.minLength") }}
                caracteres.
              </p>
            </ng-container>
          </div>
  
          <div class="form-row my-3">
            <input
              nbInput
              [(ngModel)]="user.confirmPassword"
              #rePass="ngModel"
              type="password"
              id="input-re-password"
              name="rePass"
              placeholder="Repetir contraseña"
              fullWidth
              fieldSize="large"
              autocomplete="new-password"
              [status]="
                rePass.dirty
                  ? rePass.invalid || password.value != rePass.value
                    ? 'danger'
                    : 'success'
                  : ''
              "
              [required]="getConfigValue('forms.validation.password.required')"
              [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
            />
            <ng-container
              *ngIf="
                (rePass.invalid || password.value != rePass.value) &&
                rePass.touched
              "
            >
              <p class="caption status-danger" *ngIf="rePass.errors?.required">
                <i class="fas fa-times-circle"></i>
                Necesitamos que repitas tu contraseña para asegurarnos de que es
                la correcta.
              </p>
              <p
                class="caption status-danger"
                *ngIf="password.value != rePass.value && !rePass.errors?.required"
              >
                <i class="fas fa-times-circle"></i>
                Las contraseñas no coinciden.
              </p>
            </ng-container>
          </div>
  
          <div
            class="form-row my-3 accept-group"
            *ngIf="getConfigValue('forms.register.terms')"
          >
            <nb-checkbox name="terms" [(ngModel)]="user.terms" required>
              Acepto los
              <a
                class="text-link"
                *ngIf="!businessUser"
                href="https://www.tiendadepuntos.com/tyc-usuarios"
                target="_blank"
                >términos y condiciones</a
              >
              <a
                class="text-link"
                *ngIf="businessUser"
                href="https://www.tiendadepuntos.com/tyc-comercios"
                target="_blank"
                >términos y condiciones para comercios</a
              >
              y la
              <a
                class="text-link"
                href="https://www.tiendadepuntos.com/politicas-usuarios"
                target="_blank"
                >política de privacidad</a
              >.
            </nb-checkbox>
  
            <button
              nbButton
              fullWidth
              class="puntos-button filled"
              [disabled]="submitted || !form.valid || !user.terms"
              [class.btn-pulse]="submitted"
            >
              Terminar
            </button>
          </div>
        </nb-step>
      </nb-stepper>
    </form>
  </div>
</div>
