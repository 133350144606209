/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./empty-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./empty-screen.component";
var styles_EmptyScreenComponent = [i0.styles];
var RenderType_EmptyScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmptyScreenComponent, data: {} });
export { RenderType_EmptyScreenComponent as RenderType_EmptyScreenComponent };
export function View_EmptyScreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "empty-screen-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "nb-icon", [["class", "giant-icon"]], [[8, "innerHTML", 1], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null]], null, null, i2.View_NbIconComponent_0, i2.RenderType_NbIconComponent)), i1.ɵdid(3, 638976, null, 0, i3.NbIconComponent, [i4.DomSanitizer, i3.NbIconLibraries, i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.icon; _ck(_v, 3, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).html; var currVal_1 = i1.ɵnov(_v, 3).primary; var currVal_2 = i1.ɵnov(_v, 3).info; var currVal_3 = i1.ɵnov(_v, 3).success; var currVal_4 = i1.ɵnov(_v, 3).warning; var currVal_5 = i1.ɵnov(_v, 3).danger; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = _co.title; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.message; _ck(_v, 7, 0, currVal_8); }); }
export function View_EmptyScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "puntos-empty-screen", [], null, null, null, View_EmptyScreenComponent_0, RenderType_EmptyScreenComponent)), i1.ɵdid(1, 114688, null, 0, i5.EmptyScreenComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmptyScreenComponentNgFactory = i1.ɵccf("puntos-empty-screen", i5.EmptyScreenComponent, View_EmptyScreenComponent_Host_0, { icon: "icon", title: "title", message: "message", buttonText: "buttonText", buttonAction: "buttonAction" }, {}, []);
export { EmptyScreenComponentNgFactory as EmptyScreenComponentNgFactory };
