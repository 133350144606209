import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Operator } from '../_models/operator.model';
import { Observable, ReplaySubject } from 'rxjs';
import { BusinessBranch } from '../_models/business_branch.model';
import { User } from '../_models/user.model';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  pagination_cache: Operator[] = [];

  private _operator = new ReplaySubject<Operator>(1);
  operator$ = this._operator.asObservable();
  currentOperator: Operator;

  constructor(private http: HttpClient) {
  }

  current() {
    if (!this.currentOperator) {
      return this.reloadOperator();
    } else {
      return Observable.of(this.currentOperator);
    }
  }

  setOperator(operator: Operator) {
    this.currentOperator = operator;
    this._operator.next(this.currentOperator);
  }

  clear() {
    this.currentOperator = null;
    this._operator.next(null);
  }

  reloadOperator() {
    return this.http.get('operators/current')
      .pipe(map((response: any) => {
        if (response.success) {
          return new Operator(response.data);
        } else {
          return null;
        }
      }))
      .pipe(map((operator: Operator) => {
        this.setOperator(operator);
        return this.currentOperator;
      }));
  }

  getPaginationResult(params?) {
    return this.http
      .get('operators', { params: params })
      .pipe(map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((operator) => {
          return new Operator(operator);
        });
        this.pagination_cache = pagination_result.data;
        return new PaginationResult<Operator>(pagination_result);
      }));
  }

  get(id?) {
    // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
    // if (user) return of(user);
    return this.http.get(`operators/${id}`).pipe(map((response) => new Operator(response)));
  }

  getAll() {
    return this.http
      .get(`operators`)
      .pipe(map((data: any) => {
        return data.map(operator => new Operator(operator));
      }));
  }

  save(operator: Operator) {
    const method = operator.id ? 'put' : 'post';
    let url = 'operators';

    if (method === 'put') {
      url += '/' + operator.id;
    }

    return this.http.request(method, url, { body: operator });
  }

  delete(operator: Operator) {
    return this.http.delete(`operators/${operator.id}`);
  }

  clearPassword(user: User, password: String) {
    return this.http.put(`operators/${user.id}/password`, { password } );
  }

  changeStatus(operator: Operator, enabled: boolean) {
    return this.http.put(`operators/${operator.id}/status/${enabled}`, {});
  }

  addOperatorToBranch(operator: Operator, branch: BusinessBranch) {
    return this.http.post(`branches/${branch.id}/operator/${operator.id}`, { });
  }

  deleteOperatorToBranch(operator: Operator, branch: BusinessBranch) {
    return this.http.delete(`branches/${branch.id}/operator/${operator.id}`);
  }
}
