import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Integration } from '../_models/integrations/integration.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class IntegrationService {
    constructor(http) {
        this.http = http;
    }
    save(integration) {
        const method = integration.id ? 'put' : 'post';
        let url = `integrations`;
        if (method === 'put') {
            url += `/${integration.id}`;
        }
        return this.http.request(method, url, { body: integration });
    }
    getAll(params) {
        return this.http.get(`integrations`, { params })
            .pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((integration) => {
                return new Integration(integration);
            });
            return new PaginationResult(pagination_result);
        }));
    }
    get(integrationId) {
        return this.http.get(`integrations/${integrationId}`);
    }
    delete(integration) {
        return this.http.delete(`integrations/${integration.id}`);
    }
    changeStatus(integration, enabled) {
        return this.http.put(`integrations/${integration.id}/status/${enabled}`, {});
    }
    authDragonFish(data) {
        return this.http.post(`integrations/dragonfish/auth`, data);
    }
    healthCheck(id) {
        return this.http.get(`integrations/${id}/health`);
    }
    authFudo(data) {
        return this.http.post(`integrations/fudo/auth`, data);
    }
}
IntegrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntegrationService_Factory() { return new IntegrationService(i0.ɵɵinject(i1.HttpClient)); }, token: IntegrationService, providedIn: "root" });
