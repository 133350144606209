import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Plan } from '../../_models/plan.model';
import { NbDialogRef } from '@nebular/theme';
import { PlanService } from '../../_services/plan.service';

@Component({
  selector: 'puntos-delete-plan',
  templateUrl: './delete-plan.component.html',
  styleUrls: ['./delete-plan.component.scss'],
})
export class DeletePlanComponent implements OnInit {
  @Input() plan: Plan = null;

  @Input() status: string;

  acceptButtonStatus = 'primary';
  deleteButtonStatus = 'danger';

  @Output() onDeletePlan: EventEmitter<Plan> = new EventEmitter<Plan>();

  planToTransfer: Plan = null;
  filters: {
    plan?: Plan,
  };

  constructor(
    private ref: NbDialogRef<DeletePlanComponent>,
    private planService: PlanService) { }

  ngOnInit() { }

  search = (q) => {
    return this.planService.search(q);
  }

  select(search: string, value: any) {
    this.planToTransfer = value;
  }

  accept() {
    this.onDeletePlan.emit(this.planToTransfer);
  }

  cancel() {
    this.ref.close(false);
  }

  close() {
    this.ref.close(false);
  }
}
