import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-load-file',
  templateUrl: './load-file.component.html',
  styleUrls: ['./load-file.component.scss'],
})
export class LoadFileComponent implements OnInit {

  @Input() showSendNotificationButton: boolean = false;
  @Input() sendNotificationText: string;
  @Input() status: string;
  @Input() title: string;
  @Input() message: string;
  @Input() acceptButtonText: string = 'OK';
  @Input() acceptButtonStatus: string = 'success';
  @Input() cancelButtonText: string = 'Cancelar';
  @Input() cancelButtonStatus: string = 'basic';
  @Input() exampleFile: string = null;
  @Input() exampleFilename: string = null;
  @Input() exampleFileTooltip: string = null;
  messageHtml: SafeHtml;
  fileToUpload: File | null = null;
  sendNotification: boolean = false;
  constructor(private ref: NbDialogRef<LoadFileComponent>,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.messageHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  cancel() {
    this.ref.close(null);
  }

  accept() {
    this.ref.close({ file: this.fileToUpload, sendNotification: this.sendNotification });
  }

  downloadTemplate() {
    if (this.exampleFile && this.exampleFilename) {
      const link = document.createElement('a');
      link.href = this.exampleFile;
      link.download = this.exampleFilename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('The example file URL or filename is not provided');
    }
  }
}
