import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService,
              private router: Router,
              private location: Location) {
  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        map(authenticated => {
          if (!authenticated) {
            const params: any = {};
            const path = this.location.path();
            if (path.length && path.indexOf('auth') === -1 && path.indexOf('redirect') === -1) {
              params.then = path;
            }
            this.router.navigate(['auth/login', params]);
            return false;
          }
          return true;
        }),
      );
  }

}
