/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unsubscribe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./unsubscribe.component";
import * as i3 from "@angular/router";
import * as i4 from "../../_services/user.service";
var styles_UnsubscribeComponent = [i0.styles];
var RenderType_UnsubscribeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsubscribeComponent, data: {} });
export { RenderType_UnsubscribeComponent as RenderType_UnsubscribeComponent };
export function View_UnsubscribeComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_UnsubscribeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UnsubscribeComponent_0, RenderType_UnsubscribeComponent)), i1.ɵdid(1, 114688, null, 0, i2.UnsubscribeComponent, [i3.Router, i3.ActivatedRoute, i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnsubscribeComponentNgFactory = i1.ɵccf("ng-component", i2.UnsubscribeComponent, View_UnsubscribeComponent_Host_0, {}, {}, []);
export { UnsubscribeComponentNgFactory as UnsubscribeComponentNgFactory };
