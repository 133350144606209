import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { NotificationComponent } from '../notification/notification.component';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'puntos-notification-with-click',
  templateUrl: './notification-with-click.component.html',
  styleUrls: ['./notification-with-click.component.scss'],
})
export class NotificationWithClickComponent implements OnInit {

  @Input() status: string;
  @Input() title: string;
  @Input() message: string;
  @Input() image: string;
  @Input() videoURL: string;
  @Input() buttonText = 'OK';
  @Input() buttonStatus = 'primary';
  @Input() showButton: boolean = true;
  @Input() closeButton: boolean = true;

  messageHtml: SafeHtml;
  safeURL: SafeResourceUrl;

  icon = faPlay;

  constructor(private ref: NbDialogRef<NotificationWithClickComponent>,
    private domSanitizer: DomSanitizer,
    private dialogService: NbDialogService) {
  }

  ngOnInit() {
    this.messageHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
    this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
  }

  openVideoPopup() {
    this.dialogService.open(NotificationComponent, {
      context: {
        status: 'white',
        title: this.title,
        videoURL: this.videoURL,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
    this.close();
  }

  close() {
    this.ref.close();
  }
}
