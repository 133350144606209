<pagination-template
  #p="paginationApi"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
  class="paginator"
>
  <div class="pagination" [class.disabled]="p.isFirstPage()">
    <a *ngIf="!p.isFirstPage()" (click)="p.previous()"> 
      <nb-icon icon="Arrow_Left_MD"></nb-icon> 
      <span>Anterior</span>
    </a>
  </div>

  <div class="pages">
    <div
      class="page"
      *ngFor="let page of p.pages"
      [class.current]="p.getCurrent() === page.value"
    >
      <a
        (click)="p.setCurrent(page.value)"
        *ngIf="p.getCurrent() !== page.value"
      >
        <span>{{ page.label }}</span>
      </a>
      <div *ngIf="p.getCurrent() === page.value">
        <span>{{ page.label }}</span>
      </div>
    </div>
  </div>

  <div class="pagination" [class.disabled]="p.isLastPage()">
    <a *ngIf="!p.isLastPage()" (click)="p.next()">
      <span>Siguiente</span> 
      <nb-icon icon="Arrow_Right_MD"></nb-icon>
    </a>
  </div>
</pagination-template>
