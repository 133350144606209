import { PermissionBase } from './permission_base.model';

export class PermissionProducts extends PermissionBase {
    can_create: boolean = false;
    can_edit: boolean = false;
    can_delete: boolean = false;
    can_change_status: boolean = false;

    constructor() {
        super();
    }
}
