import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { User } from '../../_models/user.model';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'puntos-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  @Input() status: string;
  title: string = 'Cambio de contraseña';
  acceptButtonText = 'Guardar';
  @Input() acceptButtonStatus = 'primary';
  @Input() cancelButtonText = 'Cancelar';
  @Input() cancelButtonStatus = 'basic';

  @Input() useOutput: boolean = false;

  @Output() onPasswordUpdate: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;
  user: User;
  saving = false;
  submitted = false;

  constructor(private userService: UserService, private ref: NbDialogRef<ChangePasswordComponent>, private formBuilder: FormBuilder, private toastrService: NbToastrService) {
    this.form = this.formBuilder.group({
      password: ['', [<any>Validators.required, <any>Validators.minLength(8), <any>Validators.maxLength(40)]],
      confirmPassword: ['', [<any>Validators.required]],
    });
  }

  ngOnInit() {
    this.userService.current().subscribe((user: User) => {
      this.user = user;
    });
  }

  get controls() { return this.form.controls; }

  save() {
    if (this.useOutput) {
      this.onPasswordUpdate.emit(this.form.value.password);
      return;
    }
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.saving = true;

    this.userService.save(new User({ 'id': this.user.id, 'password': this.form.value.password })).subscribe(() => {
      this.toastrService.success('La contraseña fue cambiada con éxito.', '¡Bien!');
      this.accept();
    }, (err) => {
      this.saving = false;
    });
  }

  cancel() {
    this.ref.close(false);
  }

  accept() {
    this.ref.close(true);
  }
}
