/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../node_modules/@nebular/auth/components/auth.component.scss.shim.ngstyle";
import * as i1 from "./auth.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../../node_modules/@nebular/theme/index.ngfactory";
import * as i4 from "@nebular/theme";
import * as i5 from "../../../node_modules/@nebular/auth/index.ngfactory";
import * as i6 from "@nebular/auth";
import * as i7 from "@angular/router";
import * as i8 from "./auth.component";
import * as i9 from "@angular/common";
import * as i10 from "../_services/business.service";
var styles_AuthComponent = [i0.styles, i1.styles];
var RenderType_AuthComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "nb-layout", [], [[8, "className", 0], [2, "window-mode", null], [2, "with-scroll", null], [2, "with-subheader", null]], [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i2.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NbLayoutComponent_0, i3.RenderType_NbLayoutComponent)), i2.ɵdid(1, 4374528, null, 0, i4.NbLayoutComponent, [i4.NbThemeService, i4.NbSpinnerService, i2.ElementRef, i2.Renderer2, i4.NB_WINDOW, i4.NB_DOCUMENT, i2.PLATFORM_ID, i4.NbLayoutDirectionService, i4.NbLayoutScrollService, i4.NbLayoutRulerService, i4.NbRestoreScrollTopHelper, i4.NbOverlayContainerAdapter], null, null), (_l()(), i2.ɵeld(2, 0, null, 3, 5, "nb-layout-column", [["style", "margin: 2rem 1rem"]], [[2, "left", null], [2, "start", null]], null, null, i3.View_NbLayoutColumnComponent_0, i3.RenderType_NbLayoutColumnComponent)), i2.ɵdid(3, 49152, null, 0, i4.NbLayoutColumnComponent, [], null, null), (_l()(), i2.ɵeld(4, 0, null, 0, 3, "nb-auth-block", [], null, null, null, i5.View_NbAuthBlockComponent_0, i5.RenderType_NbAuthBlockComponent)), i2.ɵdid(5, 49152, null, 0, i6.NbAuthBlockComponent, [], null, null), (_l()(), i2.ɵeld(6, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i2.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i2.ViewContainerRef, i2.ComponentFactoryResolver, [8, null], i2.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵinlineInterpolate(1, "", (!!((_co.business == null) ? null : _co.business.id) ? ("business-" + ((_co.business == null) ? null : _co.business.slug)) : "business-main"), ""); var currVal_1 = i2.ɵnov(_v, 1).windowModeValue; var currVal_2 = i2.ɵnov(_v, 1).withScrollValue; var currVal_3 = i2.ɵnov(_v, 1).withSubheader; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i2.ɵnov(_v, 3).leftValue; var currVal_5 = i2.ɵnov(_v, 3).startValue; _ck(_v, 2, 0, currVal_4, currVal_5); }); }
export function View_AuthComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i2.ɵdid(1, 245760, null, 0, i8.AuthComponent, [i6.NbAuthService, i9.Location, i10.BusinessService, i4.NbThemeService, i4.NbMediaBreakpointsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i2.ɵccf("ng-component", i8.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
