import { Component, Input, OnInit } from '@angular/core';
import { BusinessBranch } from '../../_models/business_branch.model';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-branches-list',
  templateUrl: './branches-list.component.html',
  styleUrls: ['./branches-list.component.scss'],
})
export class BranchesListComponent implements OnInit {
  @Input() branches: BusinessBranch[] = [];
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  constructor(private ref: NbDialogRef<BranchesListComponent>) { }

  ngOnInit() {
    this.branches = this.branches.filter(branch => branch.enabled);
  }

  accept() {
    this.ref.close(true);
  }
}
