<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <form [formGroup]="form" name="form">
          <div class="row form-group">
            <label for="inputScore" class="label col-sm-12 col-form-label">Puntos necesarios para acceder al premio</label>
            <div class="col-sm-12">
              <input nbInput fullWidth type="number" id="inputScore" name="score" class="form-control" formControlName="score">
              <ng-container *ngIf="controls.score.invalid && controls.score.touched">
                <p class="caption status-danger" *ngIf="controls.score.errors?.required">
                  Necesitamos que agregues una cantidad de puntos para el canje.
                </p>
              </ng-container>
            </div>
          </div>

          <div class="row form-group">
            <label for="inputLevel" class="label col-sm-12 col-form-label">Nivel</label>
            <div class="col-sm-12">
              <nb-select id="inputLevel" formControlName="level"
                status="{{ !controls.level.touched && !submitted ? 'default' : (controls.level.invalid ? 'danger' : 'success') }}"
                [class.is-invalid]="controls.level.invalid">
                <nb-option *ngFor="let level of levels" [value]="level.id">{{ level.name }}</nb-option>
              </nb-select>
            </div>
          </div>
          <!--<div class="row form-group">
            <label for="inputDescription" class="label col-sm-3 col-form-label">Descripción</label>
            <div class="col-sm-12">
              <textarea nbInput fullWidth type="text" id="inputDescription" name="description" class="form-control" formControlName="description"></textarea>
              <ng-container *ngIf="controls.description.invalid && controls.description.touched">
                <p class="caption status-danger" *ngIf="controls.description.errors?.required">
                  Necesitamos que agregues una descripcion para el premio.
                </p>
              </ng-container>
            </div>
          </div>-->
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="cancel()" [status]="cancelButtonStatus" class="float-left">Cancelar</button>
        <button nbButton (click)="save()" [status]="acceptButtonStatus" class="float-right">{{ textAddButton }}</button>
    </nb-card-footer>
</nb-card>