import {Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { environment } from '../environments/environment';
import { BusinessService } from './_services/business.service';
import { Router } from '@angular/router';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private businessService: BusinessService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const subdomain = this.businessService.getSubdomain() || 'mi';

    let apiReq = req.clone({
      url: environment.apiBaseUrl + `/${req.url}`,
    });

    if (subdomain) {
      apiReq = apiReq.clone({ setHeaders: { 'Puntos-Subdomain': subdomain } });
    }

    return next
      .handle(apiReq)
      .do(evt => {}, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            this.router.navigate(['auth/login']);
          }
        }
      });
  }

}
