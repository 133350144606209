import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tag } from '../_models/tag.model';
import { Observable } from 'rxjs';
import { DownloadService } from '../shared/download/download.service';
import { Read } from '../_models/read.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { ProductOffer } from '../_models/product_offer.model';


@Injectable({
  providedIn: 'root',
})
export class TagService {

  constructor(private http: HttpClient,
              private downloadService: DownloadService) { }

  one(id?: number): Tag {
    const tag = new Tag();
    if (id) {
      tag.id = id;
    }
    return tag;
  }

  get(tag: Tag): Observable<Tag> {
    return this.http.get('tags/' + tag.id )
      .pipe(map((response: any) => new Tag(response)));
  }

  getPaginationResult(params?) {
    return this.http
      .get('tags', { params: params })
      .pipe(map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((tag) => {
          return new Tag(tag);
        });
        return new PaginationResult<Tag>(pagination_result);
      }));
  }

  all(params?): Observable<Tag[]> {
    return this.http.get('tags', { params })
      .pipe(
        map((response: any) => {
          return response.map((tag) => new Tag(tag));
        }),
      );
  }

  save(tag: Tag) {

    const method = tag.id ? 'put' : 'post';
    let url = 'tags';

    if (method === 'put') {
      url += '/' + tag.id;
      return this.http.put(url, tag );
    }

    return this.http.post(url, tag );

  }

  read(id) {
    const url = `read/${id}`;
    return this.http
      .get(url, { headers: { 'Accept': 'application/json' } } )
      .pipe(
        map((r: { read, nextProductOffer }) => ({
          read: new Read(r.read),
          nextProductOffer: r.nextProductOffer ? new ProductOffer(r.nextProductOffer) : null,
        })),
      );

  }

  delete(tag) {
    const url = `tags/${tag.id}`;
    return this.http.delete(url);
  }

  download(tag: Tag) {
    return this.http
      .get('tags/' + tag.id + '/print.pdf', { responseType: 'blob' })
      .subscribe((response) => {
        this.downloadService.download('tdp-qr-' + tag.code + '.pdf', response, '');
      });
  }

  substract(data) {
    const method = 'post';
    const url = 'tags/substraction';

    return this.http.request(method, url, { body: data });
  }


}
