import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {
  @Input() title: string = '';
  @Input() status: string;
  @Input() acceptButtonStatus = 'primary';

  @Output() onDateSelected: EventEmitter<{ start: Date, end: Date }> = new EventEmitter<{ start: Date, end: Date }>();

  selectedRange: { start: Date, end: Date };

  constructor(
    private ref: NbDialogRef<DateRangeSelectorComponent>,
  ) { }

  ngOnInit() {
  }

  accept() {
    if (this.selectedRange) {
      this.onDateSelected.emit(this.selectedRange);
    }
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

}
