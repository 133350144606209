// color-utils.ts
export const darkenHexColor = (hex: string, percentage: number): string => {
  const darken = (color: number): number => {
    return Math.max(0, Math.floor(color * (1 - percentage / 100)));
  };

  const r = darken(parseInt(hex.slice(1, 3), 16));
  const g = darken(parseInt(hex.slice(3, 5), 16));
  const b = darken(parseInt(hex.slice(5, 7), 16));

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
};

export const hexToRgb = (hex: string): number[] => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

export const interpolateColor = (color1: string, color2: string, factor: number): string => {
  const c1 = hexToRgb(color1);
  const c2 = hexToRgb(color2);
  const result = c1.map((c, i) => {
    return Math.round(c + factor * (c2[i] - c));
  });
  return rgbToHex(result[0], result[1], result[2]);
};
