import { PermissionBase } from './permission_base.model';

export class PermissionExchange extends PermissionBase {
    allow_exchange_products: boolean = false;
    allow_exchange_money: boolean = false;

    constructor() {
        super();
    }
}
