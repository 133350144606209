import { Business } from './business.model';
import { ProductCoupon } from './product_coupon.model';
import { ProductImage } from './product_image.model';
import { ProductOffer } from './product_offer.model';

export class Product {

  id: number;
  active: boolean;
  name: string;
  short_description: string;
  description: string;
  image: string;
  imageSizes: any;
  terms_and_conditions?: string;

  categories?: any;
  display?: boolean = true;

  control_stock_value: number;
  control_stock: boolean;

  is_public: boolean = false;

  expiration_date: Date = null;

  business_stock_limit: number = 0;
  max_purchases: number = 0;
  time_restriction_amount?: number;
  time_restriction_unit?: number;

  loading: boolean = false;

  business_id: number;
  business: Business;

  give_coupon: boolean = false;
  coupons: ProductCoupon[];

  product_images: ProductImage[];

  product_offers: ProductOffer[];

  created_at: Date = null;
  updated_at: Date = null;
  deleted_at: Date = null;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
      this.setImageSizes();
      if (init.images) {
        this.product_images = init.images.map(image => new ProductImage(image));
      }
    }
  }

  setImage(filename: string) {
    this.image = filename;
    this.setImageSizes();
  }

  setImageSizes(): any {
    const sizes = {
      'thumb': '50x50',
      'small': '200x150',
      'standard': '400x300',
      'medium': '720x405_max',
    };

    this.imageSizes = {};

    Object.keys(sizes).forEach((size) => {
      if (typeof this.image === 'string') {
        this.imageSizes[size] = 'https://images.tiendadepuntos.com/' + sizes[size] + '/' + this.image;
      }
    });
  }

}
