import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbAuthService, NbAuthResult } from '@nebular/auth';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'puntos-callback',
  template: `<div class="text-center {{ social }}">
    <nb-icon [icon]="social" pack="fab" class="fa-3x"></nb-icon>
  </div>`,
  styleUrls: ['./callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

  social: string;
  socialAlive = true;

  constructor(private route: ActivatedRoute,
              private authService: NbAuthService,
              private router: Router) { }

  ngOnInit() {

    this.social = this.route.snapshot.params.social;

    const then = localStorage.getItem('social_redirect_uri');

    this.authService
        .authenticate(this.social)
        .pipe(takeWhile(() => this.socialAlive))
        .subscribe((authResult: NbAuthResult) => {
            if (authResult.isSuccess()) {
              localStorage.removeItem('social_redirect_uri');
              this.router.navigate([then || '/']);
            }
            return true;
        });

  }

  ngOnDestroy(): void {
    this.socialAlive = false;
  }

}
