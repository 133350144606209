import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CountryISO } from 'ngx-intl-tel-input';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'puntos-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit { // Todo: Fixear este componente
  @Input() client: Client = new Client();
  @Output() clientChange = new EventEmitter<Client>();

  phone: any;

  @ViewChild('email', { static: false }) inputEmail: ElementRef;

  CountryISO = CountryISO;

  constructor() { }

  ngOnInit() {
  }

  updateClient() {
    if (this.phone) {
      this.client.user.phone = this.phone.e164Number;
    }
    this.client.user.validEmail = this.client.user.validateEmail();
    this.clientChange.emit(this.client);
  }

}
