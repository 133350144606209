import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OperatorBranch } from '../../_models/operator_branch.model';
import { NbDialogRef } from '@nebular/theme';
import { hexToRgba } from '../../@core/utils/color-utils';

@Component({
  selector: 'puntos-operator-branch-selector',
  templateUrl: './operator-branch-selector.component.html',
  styleUrls: ['./operator-branch-selector.component.scss'],
})
export class OperatorBranchSelectorComponent implements OnInit, OnDestroy {
  @Input() operatorBranches: OperatorBranch[] = [];
  @Input() selectedBranch: OperatorBranch = null;

  @Output() onSelectBranch = new EventEmitter<OperatorBranch>();

  constructor(private ref: NbDialogRef<OperatorBranchSelectorComponent>) {}

  ngOnInit() {
    const primaryColor = document.documentElement.style.getPropertyValue('--primary-color') || '#db38b7';
    document.documentElement.style.setProperty('--primary-hover-color', hexToRgba(primaryColor || '#db38b7', 0.2));
    document.documentElement.style.setProperty('--primary-selected-color', hexToRgba(primaryColor || '#db38b7', 0.1));
  }

  confirmSelection() {
    this.onSelectBranch.emit(this.selectedBranch);
    this.close();
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--primary-hover-color');
    document.documentElement.style.removeProperty('--primary-selected-color');
  }

  close() {
    this.ref.close();
  }

  changeOperatorBranch(operatorBranch: OperatorBranch) {
    this.selectedBranch = operatorBranch;
  }

}
