import {
    NbAuthResult,
    NbOAuth2AuthStrategy,
    NbOAuth2GrantType,
    NbOAuth2AuthStrategyOptions,
    NbAuthStrategyClass,
} from '@nebular/auth';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BusinessService } from '../../../_services/business.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NB_WINDOW } from '@nebular/theme';


export class Oauth2AuthSocialStrategy extends NbOAuth2AuthStrategy {

    constructor(protected http: HttpClient,
                protected route: ActivatedRoute,
                @Inject(NB_WINDOW) protected window: any,
                protected businessService: BusinessService) {
      super(http, route, window);
    }


    authenticate(data?: any): Observable<NbAuthResult> {

      if (this.getOption('token.grantType') === NbOAuth2GrantType.PASSWORD) {
        return this.passwordToken(data.email, data.password);
      } else {
        return this
          .isRedirectResult()
          .pipe(
            switchMap((result: boolean) => {
              if (!result) {
                this.authorizeRedirect();
                return observableOf(new NbAuthResult(true));
              }

              return this
                .getAuthorizationResult()
                .pipe(switchMap((authResult) => this.http
                    .post('oauth/token', {
                        client_id: environment.oauthClientId,
                        client_secret: environment.oauthClientSecret,
                        grant_type: 'social',
                        provider: this.getName(),
                        access_token: authResult.response.access_token,
                    })
                    .pipe(map((response) =>  new NbAuthResult(
                          true,
                          response,
                          this.getOption('redirect.success'),
                          [],
                          this.getOption('defaultMessages'),
                          this.createToken(response, true),
                    ))),
                ));
            }),
          );
      }
    }

    buildRedirectUrl() {
      const parts = this.getOption('authorize.redirectUri').split('//');
      const subdomain = this.businessService.getSubdomain();
      const redirectUri = subdomain ? parts[0] + '//' + subdomain + '.' + parts[1] : this.getOption('authorize.redirectUri');

      const params = {
        response_type: this.getOption('authorize.responseType'),
        client_id: this.getOption('clientId'),
        redirect_uri: redirectUri,
        scope: this.getOption('authorize.scope'),
        ...this.getOption('authorize.params'),
      };

      const endpoint = this.getActionEndpoint('authorize');
      const query = this.urlEncodeParameters(this.cleanParams(params));

      return `${endpoint}?${query}`;
    }

    logout() {
      return Observable.of(new NbAuthResult(true, {}, '/auth/login'));
    }


  }

  @Injectable()
  export class Oauth2AuthFacebookStrategy extends Oauth2AuthSocialStrategy {
    static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
      return [Oauth2AuthFacebookStrategy, options];
    }
  }

  @Injectable()
  export class Oauth2AuthGoogleStrategy extends Oauth2AuthSocialStrategy {
    static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
      return [Oauth2AuthGoogleStrategy, options];
    }
  }
