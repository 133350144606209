<nb-card>
  <nb-card-header style="display: flex; justify-content: space-between;">
    <div *ngIf="title != 'none'">{{ title }}</div>
    <button (click)="close()" class="close close-button"><nb-icon icon="Close_MD"></nb-icon></button>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="qr" class="redemption-qr"> <img src="{{ qr }}" alt=""> </div>
    <div *ngIf="code" class="redemption-code"> {{ code?.substr(-8, 8) }} </div>
    
    <a *ngIf="link" href="{{ link }}" class="redemption-link" (click)="openLink(link)">
      <i class="fas fa-external-link-alt"></i>
      {{ link }}
    </a>

    <div *ngIf="link" class="redemption-link-container">
      <input nbInput fullWidth type="text" [value]="link" class="redemption-link" disabled>
      <button (click)="copyLink()" nbButton type="button" class="puntos-button outlined">
        <nb-icon icon="Copy"></nb-icon>
      </button>
    </div>
    
    <div class="download-button">
      <button *ngIf="code" (click)="download()" nbButton type="button" class="puntos-button filled">
        <nb-icon icon="Download"></nb-icon>
        Descargar
      </button>
    </div>
  </nb-card-body>
</nb-card>