import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputImageComponent } from './input-image.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NbButtonModule, NbIconModule, NbSpinnerModule } from '@nebular/theme';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    InputImageComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    NbIconModule,
    NbButtonModule,
    NbSpinnerModule,
    ImageCropperModule,
  ],
  exports: [
    InputImageComponent,
  ],
})
export class InputImageModule { }
