import { PermissionBase } from './permission_base.model';

export class PermissionOperate extends PermissionBase {
    allow_operate_points_by_qr: boolean;
    allow_operate_points_by_email: boolean;
    allow_operate_points_by_whatsapp: boolean;
    allow_operate_points_by_link: boolean;
    allow_operate_points_by_affiliate_card: boolean;
    allow_operate_points_by_client: boolean;

    constructor() {
        super();
    }
}
