import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, Injectable, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { AuthGuard } from './_guards/auth.guard';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthJWTInterceptor, NbTokenStorage } from '@nebular/auth';
import { APIInterceptor } from './api.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './auth/login/login.component';
import { Oauth2AuthStrategy } from './auth/strategies/oauth2/oauth-strategy';
import { RegisterTokenResolverService } from './register-token-resolver.service';
import { RegisterComponent } from './auth/register/register.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { AuthCallbackComponent } from './auth/callback/callback.component';
import { Oauth2AuthFacebookStrategy, Oauth2AuthGoogleStrategy } from './auth/strategies/oauth2/oauth-social-strategy';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import * as Sentry from '@sentry/browser';
import { NbTokenCookieStorage } from './auth/token-cookie-storage';
import { UnsubscribeComponent } from './auth/unsubscribe/unsubscribe.component';
import { registerLocaleData } from '@angular/common';
import localeAR from '@angular/common/locales/es-AR';
import { CarouselModule } from 'ngx-bootstrap/carousel';

localeAR[localeAR.length - 5][2] = '¤#,##0.00'; // Remove space between symbol and number
localeAR[localeAR.length - 2]['USD'] = ['$', '$']; // Change default symbol
registerLocaleData(localeAR);

Sentry.init({
  dsn: 'https://298d2e36233048fe841f7d57dc314aeb@sentry.io/1848225',
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    // console.error(error);
    Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    AuthCallbackComponent,
    UnsubscribeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,

    ThemeModule.forRoot(),
    CarouselModule.forRoot(),

    NbDateFnsDateModule.forRoot({format: 'dd/MM/yyyy'}),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    AuthGuard,
    RegisterTokenResolverService,
    Oauth2AuthStrategy,
    Oauth2AuthFacebookStrategy,
    Oauth2AuthGoogleStrategy,
    { provide: NbTokenStorage, useClass: NbTokenCookieStorage },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        return req.url === '/api/auth/refresh-token';
      },
    },

    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
  ],
})
export class AppModule {
}
