import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterTokenResolverService } from './register-token-resolver.service';
import { RegisterComponent } from './auth/register/register.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { AuthCallbackComponent } from './auth/callback/callback.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UnsubscribeComponent } from './auth/unsubscribe/unsubscribe.component';
import { BusinessesCurrentResolver } from './admin/businesses/_resolvers/businesses-current.resolver';
import { BusinessesCurrentForceResolver } from './admin/businesses/_resolvers/businesses-current-force.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: {
      business: BusinessesCurrentResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: () => import('../app/frontend/frontend.module').then(m => m.FrontendModule),
      }, {
        path: 'business',
        loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule),
      }, {
        path: 'admin',
        loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule),
      }, {
        path: 'public',
        loadChildren: () => import('../app/public/public.module').then(m => m.PublicModule),
      }, {
        path: 'login',
        component: LoginComponent,
      }, {
        path: 'auth',
        component: AuthComponent,
        resolve: {
          business: BusinessesCurrentForceResolver,
        },
        children: [
          {
            path: '',
            component: LoginComponent,
          }, {
            path: 'login',
            component: LoginComponent,
          }, {
            path: 'register/:token/:id',
            component: RegisterComponent,
            resolve: {
              user: RegisterTokenResolverService,
            },
          }, {
            path: 'register',
            component: RegisterComponent,
          }, {
            path: 'logout',
            component: LogoutComponent,
          }, {
            path: 'request-password',
            component: RequestPasswordComponent,
          }, {
            path: 'reset-password',
            component: ResetPasswordComponent,
          }, {
            path: 'callback/:social',
            component: AuthCallbackComponent,
          }, {
            path: 'unsubscribe',
            component: UnsubscribeComponent,
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
  paramsInheritanceStrategy: 'always',
  // enableTracing: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
