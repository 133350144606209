import { Operator } from './operator.model';

export class BusinessBranch {
  id: number;
  name: string;
  enabled: boolean;
  operators: Operator[];
  new: boolean = false;
  state: 'new' | 'deleted' | '' = '';

  constructor(name: string, id?: number, enabled: boolean = true) {
    this.name = name;
    this.enabled = enabled;
    this.operators = [];
    this.new = true;
    this.id = id;
  }
}
