import * as i0 from "@angular/core";
export class DownloadService {
    constructor() { }
    download(fileName, data, strMimeType) {
        const D = document;
        const a = D.createElement('a');
        strMimeType = strMimeType || 'application/octet-stream;charset=utf-8';
        let rawFile;
        // IE10+
        if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(new Blob([data], {
                type: strMimeType,
            }), fileName);
        }
        if ('download' in a) {
            const blob = new Blob([data], {
                type: strMimeType,
            });
            rawFile = URL.createObjectURL(blob);
            // a['setAttribute']('download', fileName);
        }
        else {
            rawFile = 'data:' + strMimeType + ',' + encodeURIComponent(data);
            // eslint-disable-next-line
            // a['setAttribute']('target', '_blank');
        }
        a.href = rawFile;
        a.target = '_blank';
        a.download = fileName;
        a['setAttribute']('style', 'display:none;');
        D.body.appendChild(a);
        // this.$timeout(() => {
        if (a.click) {
            a.click();
        }
        else if (D.createEvent) {
            const eventObj = D.createEvent('MouseEvents');
            eventObj.initEvent('click', true, true);
            a.dispatchEvent(eventObj);
        }
        D.body.removeChild(a);
        // });
    }
}
DownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadService_Factory() { return new DownloadService(); }, token: DownloadService, providedIn: "root" });
