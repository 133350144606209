import { Component } from '@angular/core';
import { NbResetPasswordComponent } from '@nebular/auth';

@Component({
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent extends NbResetPasswordComponent {


}
