import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'puntos-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss'],
})
export class EmptyScreenComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
  @Input() buttonText: string;
  @Input() buttonAction: Function;

  constructor() { }

  ngOnInit() {
  }

}
