import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'puntos-tdp-icon',
  templateUrl: './tdp-icon.component.html',
  styleUrls: ['./tdp-icon.component.scss'],
})
export class TdpIconComponent implements OnInit {
  @Input() width: string = '24';
  @Input() height: string = '24';
  @Input() primaryColor: string = 'black';
  @Input() secondaryColor: string = 'black';
  @Input() borderColor: string = 'black';

  constructor() { }

  ngOnInit() {
  }

}
