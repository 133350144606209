import * as moment from 'moment';
import { Business } from './business.model';

export type UserRole = 'admin' | 'business' | 'user';
export enum GenderType {
  male = 0,
  female = 1,
  other = 2,
  noData = 3,
}
export class User {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  description: string;

  email: string;
  dni: any;
  phone: string;

  role: UserRole;
  score: any;
  birthday: Date;
  gender: any;
  last_login: string;
  status: string;
  enabled: boolean;

  image: string;

  business: Business;
  business_id: number | null;

  // branches: BusinessBranch[];

  score_amount_ratio: number = undefined;
  email_verified_at?: moment.Moment;
  send_notification?: boolean;

  constructor(init?: any) {
    Object.assign(this, init);
    if (init && init.business) {
      this.business = new Business(init.business);
    }
  }

  validEmail: boolean = false;
  validateEmail() {
    if (!this.email || this.email.length === 0) {
      return false;
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(this.email);
  }

  validDni: boolean = false;
  validateDni() {
    const numericRegex = /^[0-9]+$/;

    if (!this.dni || this.dni.length < 7 || this.dni.length > 11 || !numericRegex.test(this.dni)) {
      return false;
    } else {
      return true;
    }
  }

  showDescription = () => {
    const firstName = this.first_name ? this.first_name : '';
    const email = this.email ? this.email : '';
    const dni = this.dni ? this.dni : '';
    const lastName = this.last_name ? this.last_name : '';
    return `${firstName} ${lastName} ${email} ${dni}`;
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

}
