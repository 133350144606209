import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Category } from '../../_models/category.model';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'puntos-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.scss'],
})
export class ProductsFilterComponent implements OnInit {
  @Input() maxPoints: number;
  @Input() categories: Category[] = [];
  @Input() sortOptions: { value: string, label: string }[] = [];

  @Output() onFiltersApplied = new EventEmitter<any>();

  points: number = 0;
  highPoints: number = 0;
  minPoints: number = 0;
  selectedCategory: Category = null;
  alphabetical = false;
  userLevel = 'todos';
  sortOrder = 'relevante';

  options: Options;
  constructor(protected ref: NbDialogRef<ProductsFilterComponent>) {}

  ngOnInit(): void {
    this.highPoints = this.maxPoints;
    this.options = {
      floor: 0,
      ceil: this.maxPoints,
    };
  }

  selectCategory(category: Category) {
    this.selectedCategory = category;
  }

  applyFilters() {
    const filters = {
      minPoints: this.points,
      maxPoints: this.highPoints,
      selectedCategory: this.selectedCategory.id,
      alphabetical: this.alphabetical,
      sortOrder: this.sortOrder,
    };
    this.onFiltersApplied.emit(filters);
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

  updateFill(event: any) {
    const value = ((event.target.value - this.minPoints) / (this.maxPoints - this.minPoints)) * 100;
    event.target.style.setProperty('--value', `${value}%`);
  }

}
