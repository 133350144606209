import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';

export type ActionEvent = Read | Purchase;

@Injectable({
  providedIn: 'root',
})
export class HistoryService {

  constructor(private http: HttpClient) {
  }

  getPaginationResult(params?) {
    return this.http
      .get('history', { params: params })
      .pipe(map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((event) => {
          return event.type === 'purchase' ? new Purchase(event) : new Read(event);
        });
        return new PaginationResult<ActionEvent>(pagination_result);
      }));
  }

  getProviderPaginationResult(params?) {
    return this.http
      .get('history/provider', { params: params })
      .pipe(map((response: any) => {
        const pagination_result = response;
        return new PaginationResult<any>(pagination_result);
      }));
  }

  get(params: any = {}) {
    return this.http
      .get('history', { params })
      .pipe(map((events: any) => events.map((event) => event.type === 'purchase' ? new Purchase(event) : new Read(event))));
  }

  cancelRead(read_id: number) {
    return this.http.delete(`reads/${read_id}`);
  }
}
