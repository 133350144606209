import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAuthModule,
  NbAuthOAuth2JWTToken,
  NbOAuth2ClientAuthMethod,
  NbOAuth2GrantType,
  NbOAuth2ResponseType,
} from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils';
import { RoleProvider } from '../role.provider';
import { environment } from '../../environments/environment';
import { Oauth2AuthStrategy } from '../auth/strategies/oauth2/oauth-strategy';
import { Oauth2AuthFacebookStrategy, Oauth2AuthGoogleStrategy } from '../auth/strategies/oauth2/oauth-social-strategy';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      Oauth2AuthStrategy.setup({
        name: 'email',
        clientId: environment.oauthClientId,
        clientSecret: environment.oauthClientSecret,
        clientAuthMethod: NbOAuth2ClientAuthMethod.REQUEST_BODY,
        baseEndpoint: 'oauth/',
        defaultMessages: ['Ya te estamos redirigiendo.'],
        defaultErrors: ['Parece que hubo un problema. Por favor intentá de nuevo.'],
        token: {
          grantType: NbOAuth2GrantType.PASSWORD,
          class: NbAuthOAuth2JWTToken,
          scope: '*',
          // redirectUri?: string;
          requireValidToken: true,
        },
        redirect: {
          success: '/dashboard', // welcome page path
          failure: null, // stay on the same page
        },
        requestPass: {
          method: 'POST',
          endpoint: 'password/email',
          redirect: {
            success: '/auth',
          },
        },
        register: {
          method: 'POST',
          endpoint: 'register',
          redirect: {
            success: '/dashboard',
          },
        },
      }),
      Oauth2AuthFacebookStrategy.setup({
        name: 'facebook',
        clientId: '1172650679587500',
        authorize: {
          endpoint: 'https://www.facebook.com/v5.0/dialog/oauth',
          responseType: NbOAuth2ResponseType.TOKEN,
          scope: 'email',
          redirectUri: environment.frontendBaseUrl + '/auth/callback/facebook',
        },
      }),
      Oauth2AuthGoogleStrategy.setup({
        name: 'google',
        clientId: '549424109650-f0bb9qtks2ah2vfpotnelur5dad5nvsi.apps.googleusercontent.com',
        authorize: {
          endpoint: 'https://accounts.google.com/o/oauth2/auth',
          responseType: NbOAuth2ResponseType.TOKEN,
          scope: 'email profile',
          redirectUri: environment.frontendBaseUrl + '/auth/callback/google',
        },
      }),
    ],
    forms: {
      login: {
        rememberMe: false,
        socialLinks: [
          { url: '#', target: '_self', icon: 'facebook-f', iconPack: 'fab', title: 'Facebook' },
          { url: '#', target: '_self', icon: 'google', iconPack: 'fab', title: 'Google' },
        ],
      },
      logout: {

      },
      register: {
        terms: true,
      },
      requestPassword: {
        strategy: 'email',
      },
      requestPass: {
        method: 'POST',
      },
      validation: {
        password: {
          minLength: 7,
          maxLength: 40,
        },
        fullName: {
          minLength: 2,
          maxLength: 40,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: ['products'],
      },
      user: {
        parent: 'guest',
      },
      business: {
        parent: 'guest',
        view: ['products', 'tags'],
        create: ['products', 'tags'],
        edit: ['products', 'tags'],
        remove: ['products', 'tags'],
      },
      admin: {
        parent: 'guest',
        view: '*',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  { provide: NbRoleProvider, useClass: RoleProvider },

  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
