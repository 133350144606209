import { UserService } from './_services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "./_services/user.service";
export class RegisterTokenResolverService {
    constructor(userService) {
        this.userService = userService;
    }
    resolve(route) {
        return this.userService.retrieveToken(route.params);
    }
}
RegisterTokenResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterTokenResolverService_Factory() { return new RegisterTokenResolverService(i0.ɵɵinject(i1.UserService)); }, token: RegisterTokenResolverService, providedIn: "root" });
