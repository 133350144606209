<nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <table *ngIf="integrations?.length" class="table table-hover table-sm">
            <tr>
                <th>Nombre</th>
                <th>Estado</th>
            </tr>
            <tr *ngFor="let integration of integrations">
                <td class="name">{{ integration?.name }}</td>
                
                <td class="status" *ngIf="integration?.connectionStatus === integrationConnectionStatus.Unknowed">
                    <nb-icon icon="Loading" [class.fa-spin]="true"></nb-icon>
                </td>

                <td class="status" *ngIf="integration?.connectionStatus === integrationConnectionStatus.Offline">
                    <i class="fas fa-exclamation-circle error-icon"></i>
                </td>

                <td class="status" *ngIf="integration?.connectionStatus === integrationConnectionStatus.Online">
                    <i class="fas fa-check-circle success-icon"></i>
                </td>
            </tr>
        </table>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">Cerrar</button>
    </nb-card-footer>
</nb-card>