<nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <div class="row">
            <label for="inputScoreAmountRatio" class="label col-sm-3 col-form-label">Aumento (%)</label>
            <div class="col-sm-9 input-group mb-3">
              <div class="row" style="display: flex; flex-wrap: nowrap; margin: 0;">
                <input nbInput fullWidth type="number" id="inputScoreAmountRatio" name="score_amount_ratio" [(ngModel)]="growth">
                <a (click)="calculate()" style="margin: auto 16px;">
                  <nb-icon icon="calculator" *ngIf="!business?.id" style="color: #d14c99; cursor: pointer;"></nb-icon>
                </a>
              </div>
            </div>
        </div>
        <div class="row" *ngIf="message">{{message}}</div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="cancel()" [status]="cancelButtonStatus" class="float-left">{{ cancelButtonText }}</button>
        <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">{{ acceptButtonText }}</button>
    </nb-card-footer>
</nb-card>