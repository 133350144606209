import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil, switchMap, filter, mapTo } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../../_services/user.service';
import { User } from '../../../_models/user.model';
import { BusinessService } from '../../../_services/business.service';
import { Business } from '../../../_models/business.model';
import { merge } from 'rxjs';
import { Router } from '@angular/router';
import { ComunnicationComponent } from '../../../_dialogs/comunnication/comunnication.component';

@Component({
  selector: 'puntos-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() hamburger: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = true;
  user: User;
  business: Business;
  showHamburger: boolean = true;
  sidebarExpanded: boolean;

  dialogStatus: boolean = false;
  private dialogRef: NbDialogRef<ComunnicationComponent>;

  innerWidth: any;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserService,
    private businessService: BusinessService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private dialogService: NbDialogService) {
  }

  ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.userService.user$.subscribe((user) => this.user = user);

    const { xl } = this.breakpointService.getBreakpointsMap();

    this.businessService.current().subscribe((business) => this.business = business);

    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.userPictureOnly = isLessThanXl;
        this.showHamburger = this.hamburger && isLessThanXl;
      });

    this.menuService.onItemSelect()
      .filter(context => context.tag === 'sidebar-menu')
      .pipe(
        switchMap(() => this.themeService.onMediaQueryChange()),
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        filter(shouldCollapse => shouldCollapse),
      )
      .subscribe(() => this.sidebarService.collapse('menu-sidebar'));

    merge(
      this.sidebarService.onCollapse().pipe(mapTo(false)),
      this.sidebarService.onExpand().pipe(mapTo(true)),
    ).subscribe((expanded) => this.sidebarExpanded = expanded);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  collapseSidebar(): boolean {
    this.sidebarService.collapse('menu-sidebar');
    return false;
  }

  expandSidebar(): boolean {
    this.sidebarService.expand('menu-sidebar');
    return false;
  }


  getNotifications() {
    if (this.dialogStatus) {
      this.dialogRef.close();
    } else {
      this.dialogRef = this.dialogService.open(ComunnicationComponent, {
        context: {
          notifications: [],
        },
        hasBackdrop: this.innerWidth < 490,
        autoFocus: false,
      });
    }

    this.dialogStatus = !this.dialogStatus;
  }

  navigateHome() {
    if (this.business.id) {
      this.router.navigate(['app/score']);
    } else {
      this.menuService.navigateHome();
    }
    return false;
  }
}
