import { NbLogoutComponent } from '@nebular/auth';
import { Component } from '@angular/core';

@Component({
  templateUrl: './logout.component.html',
})
export class LogoutComponent extends NbLogoutComponent {
    logout(strategy) {
        super.logout(strategy);
        localStorage.clear();
        localStorage.setItem('show_popup', 'true');
    }
}
