import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Operator } from '../_models/operator.model';
import { Observable, ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OperatorService {
    constructor(http) {
        this.http = http;
        this.pagination_cache = [];
        this._operator = new ReplaySubject(1);
        this.operator$ = this._operator.asObservable();
    }
    current() {
        if (!this.currentOperator) {
            return this.reloadOperator();
        }
        else {
            return Observable.of(this.currentOperator);
        }
    }
    setOperator(operator) {
        this.currentOperator = operator;
        this._operator.next(this.currentOperator);
    }
    clear() {
        this.currentOperator = null;
        this._operator.next(null);
    }
    reloadOperator() {
        return this.http.get('operators/current')
            .pipe(map((response) => {
            if (response.success) {
                return new Operator(response.data);
            }
            else {
                return null;
            }
        }))
            .pipe(map((operator) => {
            this.setOperator(operator);
            return this.currentOperator;
        }));
    }
    getPaginationResult(params) {
        return this.http
            .get('operators', { params: params })
            .pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((operator) => {
                return new Operator(operator);
            });
            this.pagination_cache = pagination_result.data;
            return new PaginationResult(pagination_result);
        }));
    }
    get(id) {
        // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
        // if (user) return of(user);
        return this.http.get(`operators/${id}`).pipe(map((response) => new Operator(response)));
    }
    getAll() {
        return this.http
            .get(`operators`)
            .pipe(map((data) => {
            return data.map(operator => new Operator(operator));
        }));
    }
    save(operator) {
        const method = operator.id ? 'put' : 'post';
        let url = 'operators';
        if (method === 'put') {
            url += '/' + operator.id;
        }
        return this.http.request(method, url, { body: operator });
    }
    delete(operator) {
        return this.http.delete(`operators/${operator.id}`);
    }
    clearPassword(user, password) {
        return this.http.put(`operators/${user.id}/password`, { password });
    }
    changeStatus(operator, enabled) {
        return this.http.put(`operators/${operator.id}/status/${enabled}`, {});
    }
    addOperatorToBranch(operator, branch) {
        return this.http.post(`branches/${branch.id}/operator/${operator.id}`, {});
    }
    deleteOperatorToBranch(operator, branch) {
        return this.http.delete(`branches/${branch.id}/operator/${operator.id}`);
    }
}
OperatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperatorService_Factory() { return new OperatorService(i0.ɵɵinject(i1.HttpClient)); }, token: OperatorService, providedIn: "root" });
