import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Client } from '../_models/client.model';
import { ClientStatus } from '../_models/_enums/client_status';
import { Level } from '../_models/level.model';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  pagination_cache: Client[] = [];

  constructor(private http: HttpClient) {}

  current() {
    return this.http.get('clients/current').pipe(
      map((response: any) => {
        if (response.success) {
          const actualClient = new Client(response.data);
          if (actualClient && actualClient.level) {
            document.documentElement.style.setProperty('--primary-level-color', actualClient.level.primary_color);
            document.documentElement.style.setProperty('--secondary-level-color', actualClient.level.secondary_color);
          }

          return actualClient;
        } else {
          return null;
        }
      }),
    );
  }

  clear() {
    // this._operator = null;
    // this._operator.next(null);
  }

  getPaginationResult(params?) {
    return this.http.get('clients', { params: params }).pipe(
      map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((client) => {
          return new Client(client);
        });
        this.pagination_cache = pagination_result.data;
        return new PaginationResult<Client>(pagination_result);
      }),
    );
  }

  downloadXlsx(params?) {
    return this.http
      .get('users/xlsx', { responseType: 'arraybuffer', params })
      .subscribe((res: any) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Clientes.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
  }

  get(id?) {
    return this.http
      .get(`clients/${id}`)
      .pipe(map((response) => new Client(response)));
  }

  save(client: Client) {
    const method = client.id ? 'put' : 'post';
    let url = 'clients';

    if (method === 'put') {
      url += '/' + client.id;
    }

    return this.http.request(method, url, { body: client });
  }

  delete(client: Client) {
    return this.http.delete(`clients/${client.id}`);
  }

  search = (query) => {
    const params: any = { query };
    return this.http.get('clients/search', { params: params });
  }

  changeStatus(
    client: Client,
    newStatus: ClientStatus,
  ) {
    return this.http.put(`clients/${client.id}/status`, {
      status: newStatus,
    });
  }

  updateUserLevel(client: Client, level: Level) {
    return this.http.put(
      `clients/${client.id}/level/${level.id}`,
      {},
    );
  }
}
