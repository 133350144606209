<div class="request-password">
  <div class="go-back-header">
    <a routerLink="/auth/login" class="go-back float-left back">
      <nb-icon icon="Chevron_Left"></nb-icon> Volver
    </a>
  </div>
  
  <h1 class="margin-top-4rem" id="title">Recuperar contraseña</h1>
  <h3 class="title-description">Por favor, ingresá tu correo electrónico con el que estas registrado para recibir las instrucciones para reestablecer tu contraseña.</h3>
  
  <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>¡Upa!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>
  
  <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>¡Bien!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>
  
  <form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">
  
    <div class="form-control-group">
      <input nbInput
             [(ngModel)]="user.email"
             #email="ngModel"
             id="input-email"
             name="email"
             pattern=".+@.+\..+"
             placeholder="Correo electrónico"
             autofocus
             fullWidth
             fieldSize="large"
             [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.email.required')"
             [attr.aria-invalid]="email.invalid && email.touched ? true : null">
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="caption status-danger" *ngIf="email.errors?.required">
          <i class="fas fa-times-circle"></i>
          Olvidaste ingresar tu email.
        </p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
          <i class="fas fa-times-circle"></i>
          Esto no parece un email válido.
        </p>
      </ng-container>
    </div>
  
    <button nbButton
            fullWidth
            class="puntos-button filled"
            size="large"
            [disabled]="submitted || !requestPassForm.valid"
            [class.btn-pulse]="submitted"
            [nbSpinner]="submitted"
    >
      Enviar
    </button>
  </form>
  
  <section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../login">Iniciar sesión</a></p>
    <p><a class="text-link" routerLink="/auth/register">Registrarme</a></p>
  </section>
  
</div>