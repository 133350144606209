import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessBranch } from '../../_models/business_branch.model';

@Component({
  selector: 'puntos-branch-selector',
  templateUrl: './branch-selector.component.html',
  styleUrls: ['./branch-selector.component.scss'],
})
export class BranchSelectorComponent implements OnInit {
  @Input() branches: BusinessBranch[] = [];

  @Output() onSelectBranch = new EventEmitter<BusinessBranch>();

  filteredBranches: BusinessBranch[] = [];

  selectedBranch: string = '';
  showDropdown: boolean = false;

  constructor() { }

  ngOnInit() {
    this.filteredBranches = this.branches;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  filterBranches(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredBranches = this.branches.filter(branch => branch.name.toLowerCase().includes(query));
    this.showDropdown = true;
  }

  selectBranch(branch: BusinessBranch) {
    this.selectedBranch = '';
    this.onSelectBranch.emit(branch);
    this.showDropdown = false;
    this.filteredBranches = this.branches;
  }

  setBranches(branches: BusinessBranch[]) {
    this.branches = branches;
    this.filteredBranches = branches;
  }

}
