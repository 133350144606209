import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ProductService } from '../../_services/product.service';
import { ProductBusiness } from '../../_models/product_business.model';
import { Level } from '../../_models/level.model';

@Component({
  selector: 'puntos-add-product-to-plan',
  templateUrl: './add-product-to-plan.component.html',
  styleUrls: ['./add-product-to-plan.component.scss'],
})
export class AddProductToPlanComponent implements OnInit {
  @Input() productId: number;
  @Input() businessId: number;
  @Input() create: boolean;
  @Input() levels: Level[];

  @Input() acceptButtonStatus = 'success';
  @Input() cancelButtonStatus = 'basic';

  @Output() onScoreUpdate: EventEmitter<number> = new EventEmitter<any>();

  title: string;
  textAddButton: string;

  form: FormGroup;
  saving = false;
  submitted = false;

  constructor(private ref: NbDialogRef<AddProductToPlanComponent>,
              private formBuilder: FormBuilder,
              private toastrService: NbToastrService,
              private productService: ProductService) {
    this.form = this.formBuilder.group({
      score: ['', [<any>Validators.required]],
      level: ['', []],
      description: ['', []],
    });
  }

  get controls() { return this.form.controls; }

  ngOnInit() {
    if (this.create) {
      this.title = 'Añadir premio a tu plan';
      this.textAddButton = 'Añadir';
    } else {
      this.title = 'Editar puntos necesarios';
      this.textAddButton = 'Aceptar';
    }
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.saving = true;

    if (this.create) {
      const pb = { 'business_id': this.businessId, 'product_id': this.productId, 'score': this.form.value.score, 'description': '', level_id: this.form.value.level };
      this.productService.addToPlan(new ProductBusiness(pb)).subscribe(() => {
        this.toastrService.success('El producto fue añadido con exito!.', '¡Bien!');
        this.accept();
      }, (err) => {
        this.toastrService.danger(err.error.error, 'Error');
        this.saving = false;
      });
    } else {
      this.productService.patchProductBusiness(this.productId, this.businessId, { score: this.form.value.score, 'description': '', level_id: this.form.value.level }).subscribe(() => {
        this.toastrService.success('El producto fue editado con exito!.', '¡Bien!');
        this.onScoreUpdate.emit(this.form.value.score);
        this.accept();
      }, (err) => {
        this.toastrService.danger(err.error.error, 'Error');
        this.saving = false;
      });
    }


  }

  cancel() {
    this.ref.close(false);
  }

  accept() {
    this.ref.close(true);
  }

}
