import { Component } from '@angular/core';
import { NbRequestPasswordComponent } from '@nebular/auth';

@Component({
  templateUrl: './request-password.component.html',
  styleUrls: ['../auth.component.scss'],
})
export class RequestPasswordComponent extends NbRequestPasswordComponent {


}
