import {
  NbAuthResult,
  NbAuthStrategyClass,
  NbOAuth2AuthStrategy,
  NbOAuth2AuthStrategyOptions,
} from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OAuth2AuthStrategyOptions } from '../oauth2-strategy.options';

@Injectable()
export class Oauth2AuthStrategy extends NbOAuth2AuthStrategy {

  static setup(options: OAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [Oauth2AuthStrategy, options];
  }

  passwordToken(username: string, password: string) {
    return super.passwordToken(username, password)
      .pipe(map((res: NbAuthResult) => new NbAuthResult(
        res.isSuccess(),
        res.getResponse(),
        res.getRedirect(),
        res.getResponse().error ? [res.getResponse().error.message] : null,
        res.getMessages(),
        res.getToken()),
    ));
  }

  requestPassword(data?: any): Observable<NbAuthResult> {
    const module = 'requestPass';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    return this.http
      .request(method, url, {body: data, observe: 'response'})
      .pipe(
        map((res) => new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            res.body['status'],
        ),
      ))
      .catch((res) => {
        return this.handleResponseError(res);
      });
  }

  resetPassword(data: any): Observable<NbAuthResult> {

    data['token'] = this.route.snapshot.queryParams.token;
    data['business_id'] = this.route.snapshot.queryParams.business_id;
    data['email'] = this.route.snapshot.queryParams.email;
    data['dni'] = this.route.snapshot.queryParams.dni;
    data['password_confirmation'] = data.confirmPassword;

    return this.http.post('oauth/password/reset', data)
      .pipe(
        map((res) => {
          return new NbAuthResult(
            true,
            res,
            this.getOption(`redirect.success`),
            ['Ocurrió un error, intentelo nuevamente'],
            res['message']);
        }),
        catchError((res: any): Observable<NbAuthResult> => {
          return this.handleResponseError(res);
        }),
      );
  }

  register(data?: any): Observable<NbAuthResult> {
    const module = 'register';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);

    data.user.password_confirmation = data.user.confirmPassword;

    return this.http.request(method, url, { body: data, observe: 'response'})
      .pipe(
        map((res: any) => {
          return new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            ['¡Login Exitoso!'],
            this.createToken(res.body, requireValidToken),
          );
        }),
        catchError((res: any): Observable<NbAuthResult> => {
          return this.handleResponseError(res);
        }),
      );
  }

  logout() {
    return Observable.of(new NbAuthResult(true, {}, '/auth/login'));
  }

}
