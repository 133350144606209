import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Client } from '../../_models/client.model';
import { TagService } from '../../_services/tag.service';

@Component({
  selector: 'puntos-confirm-substract-component',
  templateUrl: './confirm-substract.component.html',
  styleUrls: ['./confirm-substract.component.scss'],
})
export class ConfirmSubstractComponent implements OnInit {
  title: string = 'Sustraer puntos';
  acceptButtonText = 'Restar';

  @Input() acceptButtonStatus = 'primary';
  @Input() cancelButtonText = 'Cancelar';
  @Input() cancelButtonStatus = 'basic';
  @Input() client: Client;

  @Input() id: number;
  @Input() email: string;
  @Input() dni: string;
  @Input() business_id: Number;
  @Input() amount: number;

  form: FormGroup;
  submitted = false;

  constructor(
    private ref: NbDialogRef<ConfirmSubstractComponent>,
    private formBuilder: FormBuilder,
    private tagService: TagService,
  ) {

  }

  ngOnInit() {
    const group: any = {
      score: [null, [Validators.required]],
    };
    this.form = this.formBuilder.group(group);
  }

  get controls() { return this.form.controls; }

  save() {
    const amount = 0 - Math.round(+this.form.controls['score'].value / +this.amount);

    const data: any = {
      score: 0 - this.form.controls['score'].value,
      status: 'active',
      amount,
      type: 'substraction',
      business_id: this.business_id,
    };

    if (this.email && this.email.length > 0) data.email = this.email;
    if (this.dni && this.dni.length > 0) data.dni = this.dni;

    this.submitted = true;

    this.tagService.substract(data)
        .subscribe( resp => {
          const value = {
            id: this.id,
            score: this.form.controls['score'].value,
          };
          this.submitted = false;
          this.accept(value);
        });
  }

  cancel() {
    this.ref.close(null);
  }

  accept(value) {
    this.ref.close(value);
  }

}
