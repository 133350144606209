import { Business } from './business.model';
import { Operator } from './operator.model';
import { Permissions } from './permission.model';

export class PermissionGroup {
  public constructor(init?: any) {
    if (!init) return;
    if (typeof init === 'object') {
      Object.assign(this, init);
    }
    if (typeof init.permissions === 'object') {
      this.permissions = new Permissions(init.permissions);
    } else if (typeof init.permissions === 'string') {
      this.permissions = new Permissions(JSON.parse(init.permissions));
    }
  }

  id?: number;
  name: string;
  description?: string;
  permissions: Permissions = null;

  operators: Operator[];

  business: Business;
  business_id: number;
}
