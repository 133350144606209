import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Operator } from '../../_models/operator.model';

@Component({
  selector: 'puntos-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @Input() operators: Operator[] = [];
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  constructor(private ref: NbDialogRef<UsersListComponent>) { }

  ngOnInit() {
  }

  accept() {
    this.ref.close(true);
  }

}
