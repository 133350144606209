<form [formGroup]="form" name="form" novalidate>
    <nb-card [status]="status">
        <nb-card-header>Rellene los campos para generar los tokens</nb-card-header>
        <nb-card-body>
            <div class="row form-group">
                <label for="inputUser" class="label col-sm-3 col-form-label">Usuario</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth type="text" id="inputUser" name="user" class="form-control"
                        formControlName="user">
                </div>
            </div>
            <div class="row form-group">
                <label for="inputPassword" class="label col-sm-3 col-form-label">Contraseña</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth type="text" id="inputPassword" name="password" class="form-control"
                        formControlName="password">
                </div>
            </div>
            <div class="row form-group">
                <label for="inputApiKey" class="label col-sm-3 col-form-label">Clave API</label>
                <div class="col-sm-9">
                    <input nbInput fullWidth type="text" id="inputApiKey" name="api_key" class="form-control"
                        formControlName="api_key">
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="cancel()" [status]="deleteButtonStatus" class="float-left">Cancelar</button>
            <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">Crear</button>
        </nb-card-footer>
    </nb-card>
</form>