import { Component, Input, OnInit } from '@angular/core';
import { Integration, IntegrationConnectionStatus } from '../../_models/integrations/integration.model';
import { NbDialogRef } from '@nebular/theme';
import { IntegrationService } from '../../_services/integration.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'puntos-test-connections',
  templateUrl: './test-connections.component.html',
  styleUrls: ['./test-connections.component.scss'],
})
export class TestConnectionsComponent implements OnInit {
  @Input() integrations: Integration[] = [];
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  integrationConnectionStatus = IntegrationConnectionStatus;

  constructor(
    private ref: NbDialogRef<TestConnectionsComponent>,
    private integrationService: IntegrationService,
  ) { }

  ngOnInit() {
    this.performHealthChecks(0);
  }

  performHealthChecks(index: number) {
    if (index < this.integrations.length) {
      const integration = this.integrations[index];
      this.integrationService.healthCheck(integration.id).pipe(
        finalize(() => {
          this.performHealthChecks(index + 1);
        }),
      ).subscribe(
        () => {
          integration.connectionStatus = IntegrationConnectionStatus.Online;
        },
        (error) => {
          integration.connectionStatus = IntegrationConnectionStatus.Offline;
          console.error(`Error en healthCheck para integración ${integration.id}:`, error);
        },
      );
    }
  }

  accept() {
    this.ref.close(true);
  }

}
