import { PermissionExchange } from './permission/permission_exchange.model';
import { PermissionHistory } from './permission/permission_history.model';
import { PermissionOperate } from './permission/permission_operate.model';
import { PermissionHistoryProvider } from './permission/permission_history_provider.model';
import { PermissionProducts } from './permission/permission_products.model';
import { PermissionProductsShop } from './permission/permission_products_shop.model';
import { PermissionClients } from './permission/permission_clients.model';
import { PermissionValidate } from './permission/permission_validate.model';
import { PermissionStatistic } from './permission/permission_statistic.model';
import { PermissionIntegration } from './permission/permission_integration.model';
import { PermissionLevel } from './permission/permission_level.model';
import { PermissionSurvey } from './permission/permission_survey.model';

export class Permissions {
  exchange: PermissionExchange = new PermissionExchange();
  operate: PermissionOperate = new PermissionOperate();
  validate: PermissionValidate = new PermissionValidate();
  history: PermissionHistory = new PermissionHistory();
  history_provider: PermissionHistoryProvider = new PermissionHistoryProvider();
  products: PermissionProducts = new PermissionProducts();
  products_shop: PermissionProductsShop = new PermissionProductsShop();
  clients: PermissionClients = new PermissionClients();
  statistics: PermissionStatistic = new PermissionStatistic();
  integrations: PermissionIntegration = new PermissionIntegration();
  levels: PermissionLevel = new PermissionLevel();
  surveys: PermissionSurvey = new PermissionSurvey();

  constructor(init?: any) {
    Object.assign(this, init);
  }

  transformToString() {
    JSON.stringify(this);
  }
}
