import { Component, OnInit, Input } from '@angular/core';
import { HistoryTransaction } from '../../../_models/history/history_transaction';
import { Business } from '../../../_models/business.model';

/* tslint:disable:component-selector */
@Component({
  selector: '[puntos-history-purchase]',
  templateUrl: './history-purchase.component.html',
  styleUrls: ['./history-purchase.component.scss'],
})
export class HistoryPurchaseComponent implements OnInit {

  @Input() transaction: HistoryTransaction;
  @Input() business: Business = null;
  @Input() role = 'user';

  showname: string = '';
  productName: string = '';
  status: string = '';
  constructor() { }

  ngOnInit() {
    if (this.transaction) {
      if (this.transaction.user_name) {
        this.showname = this.transaction.user_name;
      } else if (this.transaction.user_email) {
        this.showname = this.transaction.user_email;
      } else if (this.transaction.user_dni) {
        this.showname = this.transaction.user_dni;
      }

      if (this.transaction.product_name) {
        this.productName = this.transaction.product_name;
      } else {
        this.productName = 'Canje por dinero';
      }

      if (this.transaction.status === 'delivered') {
        this.status = 'Entregado';
      } else if (this.transaction.status === 'pending') {
        this.status = 'Pendiente';
      } else {
        this.status = '';
      }
    }
  }



}
