import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'puntos-next-product-cluster',
  templateUrl: './next-product-cluster.component.html',
  styleUrls: ['./next-product-cluster.component.scss'],
})
export class NextProductClusterComponent implements OnInit {
  @Input() client: Client;

  nextProductPercentage: number;
  missingPoints: number;

  constructor() { }

  ngOnInit() {
    this.nextProductPercentage = (this.client.score * 100) / this.client.nextProductOffer.value;
    this.missingPoints = this.client.nextProductOffer.value - this.client.score;
  }

}
