import { PermissionBase } from './permission_base.model';

export class PermissionIntegration extends PermissionBase {
    mercado_pago: boolean = false;
    mercado_pago_webhook: boolean = false;
    payway: boolean = false;
    dragon_fish: boolean = false;
    centum: boolean = false;

    constructor() {
        super();
    }
}
