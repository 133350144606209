import { Injectable } from '@angular/core';
import { NbAuthToken, NbAuthTokenParceler } from '@nebular/auth';
import { environment } from '../../environments/environment';
import * as Cookies from 'js-cookie';

export abstract class NbTokenStorage {

  abstract get(): NbAuthToken;
  abstract set(token: NbAuthToken);
  abstract clear();
}

@Injectable()
export class NbTokenCookieStorage extends NbTokenStorage {

  protected key = 'puntos_auth';
  protected domain = '.puntos.com';
  protected path = '/';

  constructor(private parceler: NbAuthTokenParceler) {
    super();
    if (!environment.production && environment.develop) {
      this.domain = window.location.hostname;
    } else {
      const url = new URL(environment.frontendBaseUrl);
      this.domain =  url.host.split(':').shift();
    }

    this.key = this.key + ((environment.production) ? '_prod' : '_demo');
  }

  get(): NbAuthToken {
    const raw = Cookies.get(this.key);
    return this.parceler.unwrap(raw);
  }

  set(token: NbAuthToken) {
    const raw = this.parceler.wrap(token);
    Cookies.set(this.key, raw, { expires: 3600, path: this.path, domain: this.domain, secure: true, sameSite: 'None' });
  }

  clear() {
    Cookies.remove(this.key, { path: this.path, domain: this.domain });
  }
}
