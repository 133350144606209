<nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <table *ngIf="operators?.length" class="table table-hover table-sm">
            <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Última conexión</th>
            </tr>
            <tr *ngFor="let operator of operators">
                <td class="name">{{ operator?.user?.first_name }} {{ operator?.user?.last_name }}</td>
                <td class="email">{{ operator?.user?.email }}</td>
                <td class="last-login">{{ operator?.user?.last_login }}</td>
            </tr>
        </table>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">Cerrar</button>
    </nb-card-footer>
</nb-card>