<nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <table *ngIf="productOffers?.length" class="table table-hover table-sm">
            <tr>
                <th class="name">Nombre</th>
                <th class="score">Puntos necesarios</th>
            </tr>
            <tr *ngFor="let productOffer of productOffers">
                <td class="name text-center">{{ productOffer?.product.name }}</td>
                <td class="score text-center">{{ productOffer?.score | number : "1.0-0" }}</td>
            </tr>
        </table>
        <puntos-empty-screen
            *ngIf="!productOffers?.length"
            [icon]="'Gift'"
            [title]="'Aún no tienes premios creados.'"
            [message]="'Para crear un premio presiona el botón + que se encuentra en la parte superior derecha de la sección catalogo.'"
        ></puntos-empty-screen>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">Ok</button>
    </nb-card-footer>
</nb-card>