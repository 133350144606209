/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/router";
import * as i5 from "./menu.component";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nb-card", [], [[2, "size-tiny", null], [2, "size-small", null], [2, "size-medium", null], [2, "size-large", null], [2, "size-giant", null], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null], [2, "accent", null], [2, "accent-primary", null], [2, "accent-info", null], [2, "accent-success", null], [2, "accent-warning", null], [2, "accent-danger", null]], null, null, i2.View_NbCardComponent_0, i2.RenderType_NbCardComponent)), i1.ɵdid(1, 49152, null, 0, i3.NbCardComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 1, "nb-menu", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NbMenuComponent_0, i2.RenderType_NbMenuComponent)), i1.ɵdid(3, 4440064, null, 0, i3.NbMenuComponent, [i3.NB_WINDOW, i3.ɵa, i4.Router], { items: [0, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_16 = _co.menu; _ck(_v, 3, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).tiny; var currVal_1 = i1.ɵnov(_v, 1).small; var currVal_2 = i1.ɵnov(_v, 1).medium; var currVal_3 = i1.ɵnov(_v, 1).large; var currVal_4 = i1.ɵnov(_v, 1).giant; var currVal_5 = i1.ɵnov(_v, 1).primary; var currVal_6 = i1.ɵnov(_v, 1).info; var currVal_7 = i1.ɵnov(_v, 1).success; var currVal_8 = i1.ɵnov(_v, 1).warning; var currVal_9 = i1.ɵnov(_v, 1).danger; var currVal_10 = i1.ɵnov(_v, 1).hasAccent; var currVal_11 = i1.ɵnov(_v, 1).primaryAccent; var currVal_12 = i1.ɵnov(_v, 1).infoAccent; var currVal_13 = i1.ɵnov(_v, 1).successAccent; var currVal_14 = i1.ɵnov(_v, 1).warningAccent; var currVal_15 = i1.ɵnov(_v, 1).dangerAccent; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); }); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "puntos-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 245760, null, 0, i5.MenuComponent, [i3.NbDialogRef, i3.NbMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("puntos-menu", i5.MenuComponent, View_MenuComponent_Host_0, { menu: "menu", tag: "tag" }, {}, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
