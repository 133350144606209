export class HistoryTransaction {
  id: number;
  score: number;
  amount: number;
  money_amount: number;
  business_name: string;
  business_branch_name: string;
  user_name: string;
  user_email: string;
  user_dni: string;
  tag_type: string;
  reason: string;
  operator_name: string;
  product_name: string;
  product_image: string;
  extra_data: string;
  external_id: string;
  status: string;

  created_at: string;
  updated_at: string;

  user_id: number;
  business_id: number;
  business_branch_id: number;
  tag_id: number;
  read_id: number;
  purchase_id: number;
  product_id: number;

  public constructor(init?: any) {
    Object.assign(this, init);
  }

  getMethodName() {
    if (this.tag_type === 'qr-fixed' || this.tag_type === 'qr-variable') {
      return 'QR';
    } else if (this.tag_type === 'email') {
      return 'Email';
    } else if (this.tag_type === 'whatsapp') {
      return 'Whatsapp';
    } else if (this.tag_type === 'client') {
      return 'Suma directa';
    } else if (this.tag_type === 'affiliate-card') {
      return 'Tarjeta de afiliado';
    } else if (this.tag_type === 'chrome_extension') {
      return 'Extensión de Google Chrome';
    } else if (this.tag_type === 'birthday') {
      return 'Cumpleaños';
    } else if (this.tag_type === 'substraction') {
      return 'Sustracción';
    } else if (this.tag_type === 'level-add') {
      return 'Subida de nivel';
    } else if (this.tag_type === 'level-sub') {
      return 'Bajada de nivel';
    } else if (this.tag_type === 'integration-add' || this.tag_type === 'add') {
      return 'Suma desde integración';
    } else if (this.tag_type === 'integration-sub' || this.tag_type === 'sub') {
      return 'Sustracción desde integración';
    } else if (this.tag_type === 'survey') {
      return 'Encuesta';
    } else if (this.tag_type === 'migration') {
      return 'Migración inicial';
    } else if (this.tag_type === 'gift-card') {
      return 'Gift Card';
    } else if (this.product_name && this.product_name.length) {
      return 'Canje';
    } else {
      return 'Canje por dinero';
    }
  }
}
