import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../_services/user.service';

@Component({
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {

  email: string;
  type: string;

  constructor(protected router: Router, protected route: ActivatedRoute, protected userService: UserService) {
    this.route.queryParams.subscribe(params => {
      this.email = params.email;
      this.type = params.type;
     });
  }

  ngOnInit() {
    if (confirm('¿Estas seguro que deseas dejar de recibir estas notificaciones?')) {
      this.userService.unsubscribe(this.email, this.type).subscribe(() => { this.router.navigate(['/']); });
    } else {
      this.router.navigate(['/']);
    }
  }

}
