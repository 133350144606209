import { IntegrationType } from '../_enums/integration_type';
import { Business } from '../business.model';


export class IntegrationStatus {
  constructor(init?: string | object | null) {
    if (init !== null) {
      const status = typeof(init) === 'string' ? JSON.parse(init) : init;
      this.lastQueryOperationStatus = new Date(status.lastQueryOperationStatus);
      this.lastQueryOperationSucceededStatus = new Date(status.lastQueryOperationSucceededStatus);
      this.lastQueryOperationWithResultsStatus = new Date(status.lastQueryOperationWithResultsStatus);
    } else {
      this.lastQueryOperationStatus = new Date();
    }
  }

  lastQueryOperationWithResultsStatus: Date;
  lastQueryOperationSucceededStatus: Date = null;
  lastQueryOperationStatus: Date = null;
}
export abstract class IntegrationConfigBase {

  constructor(init?: any) {
    Object.assign(this, init);
  }

  abstract parseToString(data?: any): string;
  abstract parseToObject(data?: string): IntegrationConfigBase;

}

export enum IntegrationConnectionStatus {
  Online = 0,
  Offline = 1,
  Unknowed = 2,
}
export class Integration {
  id: number;
  name: string;
  enabled: boolean;

  configuration: null | string | IntegrationConfigBase;

  type: IntegrationType = IntegrationType.DEFAULT;

  business: Business;
  business_id: number | null;

  status: null | string | IntegrationStatus;

  connectionStatus: IntegrationConnectionStatus = IntegrationConnectionStatus.Unknowed;

  constructor(init?: any) {
    if (init) {
      init.status = init.status ? new IntegrationStatus(init.status) : new IntegrationStatus();
      init.business = init.business ? new Business(init.business) : null;
    }
    Object.assign(this, init);
  }
}
