import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Business } from '../../_models/business.model';

@Component({
  selector: 'puntos-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  @Input() status: string;
  title: string = '¿Cuanto variaron tus precios?';
  acceptButtonText = 'Aplicar';
  @Input() acceptButtonStatus = 'primary';
  @Input() cancelButtonText = 'Cancelar';
  @Input() cancelButtonStatus = 'basic';
  @Input() score_amount_ratio = 0;

  @Input() business: Business = null;

  @Output() onSetNewScoreAmountRatio: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSetGrowthPercentage: EventEmitter<number> = new EventEmitter<number>();

  growth: number = null;
  newValue: number = 0;
  message: string = null;

  constructor(private ref: NbDialogRef<CalculatorComponent>) { }

  ngOnInit() {
  }

  calculate() {
    if (this.growth === null) {
      this.message = 'Debe ingresar un valor de Aumento (%)';
    } else if (this.growth <= -100) {
      this.message = 'No puede haber una disminucion del precio del 100%';
    } else if (this.growth > 0) {
      const aux = (this.growth / 100) * this.score_amount_ratio;
      this.newValue = this.score_amount_ratio - aux;
    } else {
      const aux = (this.growth / 100) * this.score_amount_ratio;
      this.newValue = this.score_amount_ratio + aux;
    }

    this.message = `Tus puntos a entregar por $ cobrado deberia ser: ${this.newValue}`;
  }

  accept() {
    this.calculate();
    this.onSetNewScoreAmountRatio.emit(this.newValue);
    this.onSetGrowthPercentage.emit(this.growth);
    this.ref.close(false);
  }

  cancel() {
    this.ref.close(false);
  }

}
