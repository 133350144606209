/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tdp-pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "ngx-pagination";
import * as i7 from "./tdp-pagination.component";
var styles_TdpPaginationComponent = [i0.styles];
var RenderType_TdpPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TdpPaginationComponent, data: {} });
export { RenderType_TdpPaginationComponent as RenderType_TdpPaginationComponent };
function View_TdpPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 1).previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nb-icon", [["icon", "Arrow_Left_MD"]], [[8, "innerHTML", 1], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null]], null, null, i2.View_NbIconComponent_0, i2.RenderType_NbIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.NbIconComponent, [i4.DomSanitizer, i3.NbIconLibraries, i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Anterior"]))], function (_ck, _v) { var currVal_6 = "Arrow_Left_MD"; _ck(_v, 2, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).html; var currVal_1 = i1.ɵnov(_v, 2).primary; var currVal_2 = i1.ɵnov(_v, 2).info; var currVal_3 = i1.ɵnov(_v, 2).success; var currVal_4 = i1.ɵnov(_v, 2).warning; var currVal_5 = i1.ɵnov(_v, 2).danger; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_TdpPaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 1).setCurrent(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_0); }); }
function View_TdpPaginationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_0); }); }
function View_TdpPaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page"]], [[2, "current", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdpPaginationComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdpPaginationComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v.parent, 1).getCurrent() !== _v.context.$implicit.value); _ck(_v, 2, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); _ck(_v, 0, 0, currVal_0); }); }
function View_TdpPaginationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 1).next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Siguiente"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "nb-icon", [["icon", "Arrow_Right_MD"]], [[8, "innerHTML", 1], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null]], null, null, i2.View_NbIconComponent_0, i2.RenderType_NbIconComponent)), i1.ɵdid(4, 638976, null, 0, i3.NbIconComponent, [i4.DomSanitizer, i3.NbIconLibraries, i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_6 = "Arrow_Right_MD"; _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).html; var currVal_1 = i1.ɵnov(_v, 4).primary; var currVal_2 = i1.ɵnov(_v, 4).info; var currVal_3 = i1.ɵnov(_v, 4).success; var currVal_4 = i1.ɵnov(_v, 4).warning; var currVal_5 = i1.ɵnov(_v, 4).danger; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_TdpPaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "pagination-template", [["class", "paginator"]], null, [[null, "pageChange"], [null, "pageBoundsCorrection"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.pageChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("pageBoundsCorrection" === en)) {
        var pd_1 = (_co.pageBoundsCorrection.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, [["p", 4]], 0, i6.PaginationControlsDirective, [i6.PaginationService, i1.ChangeDetectorRef], null, { pageChange: "pageChange", pageBoundsCorrection: "pageBoundsCorrection" }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "pagination"]], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdpPaginationComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdpPaginationComponent_2)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "pagination"]], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TdpPaginationComponent_5)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = !i1.ɵnov(_v, 1).isFirstPage(); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 1).pages; _ck(_v, 7, 0, currVal_2); var currVal_4 = !i1.ɵnov(_v, 1).isLastPage(); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isFirstPage(); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 1).isLastPage(); _ck(_v, 8, 0, currVal_3); }); }
export function View_TdpPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "puntos-tdp-pagination", [], null, null, null, View_TdpPaginationComponent_0, RenderType_TdpPaginationComponent)), i1.ɵdid(1, 114688, null, 0, i7.TdpPaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TdpPaginationComponentNgFactory = i1.ɵccf("puntos-tdp-pagination", i7.TdpPaginationComponent, View_TdpPaginationComponent_Host_0, { id: "id", maxSize: "maxSize" }, { pageChange: "pageChange", pageBoundsCorrection: "pageBoundsCorrection" }, []);
export { TdpPaginationComponentNgFactory as TdpPaginationComponentNgFactory };
