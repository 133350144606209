import { Business } from './business.model';
import { ProductOffer } from './product_offer.model';

export class Level {
  id: number;
  name: string;
  description: string;

  score_amount_ratio: number;
  min_score: number;

  primary_color: string;
  secondary_color: string;
  border_color: string;

  business_id: number;
  business: Business;

  next_level_id: number;
  nextLevel: Level;

  product_offers: ProductOffer[];

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
    }

    if (init && init.next_level) {
      this.nextLevel = new Level(init.next_level);
    }
  }

}
