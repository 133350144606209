import { PermissionBase } from './permission_base.model';

export class PermissionLevel extends PermissionBase {
    can_create: boolean;
    can_edit: boolean;
    can_delete: boolean;

    constructor() {
        super();
    }
}
