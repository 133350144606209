import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, format: string = 'yyyy-MM-dd HH:mm:ss'): string | null {
    if (!value) {
      return null;
    }
    const datePipe = new DatePipe('es-AR');
    return datePipe.transform(value, format);
  }

}
