import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Business } from '../_models/business.model';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { DOCUMENT } from '@angular/common';
import { HistoryService } from './history.service';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { environment } from '../../environments/environment';
import { BusinessBranch } from '../_models/business_branch.model';
import { User, UserRole } from '../_models/user.model';

export type ActionEvent = Read | Purchase;

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  business = new Business();
  private _business = new ReplaySubject<Business>(1);
  business$ = this._business.asObservable();
  localStorageKey: string;
  subdomain: string;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private historyService: HistoryService,
  ) {
    this.subdomain = this.getSubdomain();
    this.localStorageKey = `business_${this.subdomain}`;
    this.getCurrent();
  }

  one(id?: number): Business {
    const business = new Business();
    if (id) {
      business.id = id;
    }
    return business;
  }

  get(business: Business): Observable<Business> {
    return this.http.get('businesses/' + business.id).pipe(
      map((response: any) => {
        return new Business(response);
      }),
    );
  }

  getBySlug(slug): Observable<Business> {
    return this.http.get('businesses/slug/' + slug).pipe(
      map((response: any) => {
        return new Business(response);
      }),
    );
  }

  getPaginationResult(
    page,
    per_page,
    name?,
  ): Observable<PaginationResult<Business>> {
    const params: any = { page: page, per_page: per_page };
    if (name) {
      params.name = name;
    }

    return this.http.get('businesses', { params: params }).pipe(
      map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((business) => {
          return new Business(business);
        });
        return new PaginationResult<Business>(pagination_result);
      }),
    );
  }

  all(params?): Observable<Business[]> {
    return this.http.get('businesses', { params: params }).pipe(
      map((response: any) => {
        return response.map((business) => {
          return new Business(business);
        });
      }),
    );
  }

  save(business: any) {
    const method = business.id ? 'put' : 'post';
    let url = 'businesses';

    if (method === 'put') {
      url += '/' + business.id;
    }

    return this.http.request(method, url, { body: business });
  }

  delete(business: Business) {
    return this.http.delete(`businesses/${business.id}`);
  }

  resendVerification(business: Business) {
    return this.http.put(`businesses/${business.id}/activation`, business);
  }

  getBusinessFromStorage(): Business {
    return new Business(JSON.parse(localStorage.getItem(this.localStorageKey)));
  }

  getCurrent(force = false) {
    if (this.subdomain === 'mi') {
      this.business = new Business();
      this._business.next(this.business);
      return Observable.of(this.business);
    }

    if (this.subdomain === 'www') {
      this.document.location.href = this.document.location.href.replace(
        'www.',
        '',
      );
    }

    if (localStorage.getItem(this.localStorageKey) === null || force) {
      return this.reloadBusiness().pipe(
        catchError((err) => {
          if (err.status === 404) {
            this.document.location.href = '/notfound.html';
          }
          return throwError('Invalid subdomain!');
        }),
      );
    } else {
      this.business = new Business(
        JSON.parse(localStorage.getItem(this.localStorageKey)),
      );
      this._business.next(this.business);
      return Observable.of(this.business);
    }
  }

  reloadBusiness() {
    if (!this.getSubdomain()) {
      return Observable.of(this.one());
    }

    return this.getBySlug(this.getSubdomain()).pipe(
      tap((business) =>
        localStorage.setItem(this.localStorageKey, JSON.stringify(business)),
      ),
      map((business: Business) => {
        this.business = business;
        this._business.next(this.business);
        return this.business;
      }),
    );
  }

  current() {
    return this.getCurrent();
  }

  clear() {
    if (this.business) {
      localStorage.removeItem(`business_${this.business.slug}`);
    }
    this.business = null;
    this._business.next(this.business);
  }

  getSubdomain() {
    const subdomain = window.location.hostname
      .replace(environment.frontendBaseUrl.split('//')[1], '')
      .split('.');
    if (subdomain.length > 1) {
      return subdomain[0];
    }

    return null;
  }

  getLink(business: Business) {
    return business.link;
  }

  getCategories() {
    return this.http.get(`businesses/categories`).pipe(
      map((categories: any) =>
        categories.map(function (category) {
          return category;
        }),
      ),
    );
  }

  getPlans() {
    return this.http.get(`business-plans`).pipe(
      map((plans: any) =>
        plans.map(function (plan) {
          return plan;
        }),
      ),
    );
  }

  getHistory(business: Business, params: any = {}) {
    params = { params };
    if (business.id) {
      params.business = business.id;
    }

    return this.historyService
      .get(params)
      .pipe(
        map((events: ActionEvent[]) =>
          events.map((event) =>
            event.type === 'purchase' ? new Purchase(event) : new Read(event),
          ),
        ),
      );
  }

  getBranches(business?: Business) {
    const params = new HttpParams();
    if (business) {
      params.set('business_id', business.id.toString());
    }
    return this.http.get(`branches`).pipe(
      map((response: BusinessBranch[]) => {
        return response;
      }),
    );
  }

  suspended(status, business_id) {
    const suspended = status;
    return this.http.put(`businesses/${business_id}/hired-plan/suspended`, {
      suspended,
    });
  }

  renew(business_id) {
    return this.http.put(`businesses/${business_id}/hired-plan/renew`, {});
  }

  getAllBranches() {
    return this.http.get(`businesses/branches`).pipe(
      map((response: any) => {
        const data = response.map((branch) => {
          return new BusinessBranch(branch.name, branch.id, branch.enabled);
        });
        return data;
      }),
    );
  }

  changeUserRole(business: Business, user: User, role: UserRole) {
    return this.http.put(`businesses/${business.id}/user/${user.id}/role`, {
      role,
    });
  }
}
