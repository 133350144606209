import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { tap, filter, debounceTime } from 'rxjs/operators';
import { NbSelectComponent } from '@nebular/theme';

type SearchFunction = (q: string) => Observable<any[]> | any[];


@Component({
  selector: 'puntos-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnChanges {

  @ViewChild('selectInput', { static: false }) selectInput: NbSelectComponent<any>;

  @Input('disabled') disabled: boolean = false;
  @Input('type') type: string = 'text';
  @Input('label') label: string;
  @Input('list') list: any[] | SearchFunction;
  @Input('selected') selected: null | any;
  @Input('onShowItemDescription') onShowItemDescription: (value: any) => string = null;
  @Input('onClear') onClear: number = 0;
  @Input('useOnClear') useOnClear: boolean = false;
  @Input('isValidItem') isValidItem: (item: any) => string;
  @Input('onClickSearch') onClickSearch: boolean = false;
  @Input('keywordSelect') keywordSelect: string = 'range';
  @Input('isRounded') isRounded: boolean = false;


  @Output() public onSelect = new EventEmitter<any>();

  searchControl: FormControl;
  selectControl: FormControl;

  results: null | any[];
  loading = true;

  showResults: boolean = false;

  constructor() { }

  ngOnInit() {
    this.searchControl = new FormControl();

    if (this.disabled) {
      this.searchControl.disable();
    }

    if (this.type === 'text') {
      this.searchControl.valueChanges.pipe(debounceTime(400)).subscribe((q) => {
        if ((!q || q.length === 0)) return;
        this.triggerSearch();
      });
    } else if (this.type === 'select') {
      this.selectControl = new FormControl(this.selected ? this.selected[this.keywordSelect] : null);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) return;

    if (this.useOnClear) {
      if (changes.onClear.currentValue > 0) {
        this.clear();
      }
    }

    if (this.searchControl) {
      if (this.disabled) {
        this.searchControl.disable();
      } else {
        if (this.searchControl.disabled) {
          this.searchControl.enable();
        }
      }
    }
  }

  search(q): Observable<any[]> {

    if (typeof this.list === 'object' && this.type === 'select') {
      return of(this.list);
    }

    if (typeof this.list === 'object' && this.type === 'text') {
      return of(this.list.filter((item) => {
        const name = this.accentFold(item.name);
        q = this.accentFold(q);
        return name.search(new RegExp(q, 'i')) !== -1;
      }));
    }

    if (typeof this.list === 'function') {
      return this.list.call(null, q);
    }
  }

  triggerSearch() {
    const q = this.searchControl.value || '';
    this.search(q).pipe(
      filter(() => !this.disabled),
      tap(() => this.loading = false),
    ).subscribe((results) => {
      this.results = results;
      this.showResults = true;
    });
  }

  onClickSearchBox() {
    if (!this.onClickSearch) return;
    this.triggerSearch();
  }

  accentFold(s) {
    const accent_map = {
      'á': 'a', 'é': 'e', 'è': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
      'Á': 'a', 'É': 'e', 'È': 'e', 'Í': 'i', 'Ó': 'o', 'Ú': 'u',
    };
    if (!s) { return ''; }
    let ret = '';
    for (let i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }

  selectSelect(e) {
    if (typeof this.list === 'object') {
      this.selected = this.list.filter((item) => item[this.keywordSelect] === e).pop();
      this.onSelect.emit(this.selected);
    }
  }

  selectItem(item) {
    if (this.isValidItem) {
      const result = this.isValidItem(item);
      if (!result) {
        return;
      }
    }
    this.selected = item;
    this.results = null;
    this.onSelect.emit(item);
  }

  getItemDescription(item) {
    if (this.onShowItemDescription) {
      return this.onShowItemDescription(item);
    }
    return item.name ? item.name : item.full_name;
  }

  getStyles(item) {
    if (this.isValidItem) {
      return { 'disabled': !this.isValidItem(item) };
    }

    return {};
  }

  clear() {
    this.selected = null;
    if (this.type === 'text') {
      this.results = null;
    } else if (this.type === 'select') {
      this.selectControl.setValue(null);
    }
    this.onSelect.emit(null);
    this.searchControl.setValue('');
    this.showResults = false;
  }

  onBlurInput() {
    setTimeout(() => {
      if (!this.selected) this.clear();
    }, 500);
  }

}
