import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Level } from '../../_models/level.model';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-update-client-level',
  templateUrl: './update-client-level.component.html',
  styleUrls: ['./update-client-level.component.scss'],
})
export class UpdateClientLevelComponent implements OnInit {
  @Input() levels: Level[] = [];
  @Output() onSetLevel = new EventEmitter<Level>();

  status: string = 'basic';
  acceptButtonStatus = 'success';
  deleteButtonStatus = 'danger';

  constructor(private ref: NbDialogRef<UpdateClientLevelComponent>) { }

  selectedLevel: Level = null;

  ngOnInit() {
    if (this.levels.length > 0) {
      this.selectedLevel = this.levels[0];
    }
  }

  accept() {
    this.onSetLevel.emit(this.selectedLevel);
    this.ref.close();
  }

  cancel() {
    this.ref.close();
  }
}
