import { Component, Input, OnInit } from '@angular/core';
import { Client } from '../../_models/client.model';

@Component({
  selector: 'puntos-level-cluster',
  templateUrl: './level-cluster.component.html',
  styleUrls: ['./level-cluster.component.scss'],
})
export class LevelClusterComponent implements OnInit {

  @Input() client: Client = null;

  missingPoints: number = 0;
  nextLevelPercentage: number = 0;

  constructor() { }

  ngOnInit() {
    if (this.client.nextLevel) {
      this.missingPoints = this.client.nextLevel.min_score - this.client.total_score;
      this.nextLevelPercentage = (this.client.total_score * 100) / this.client.nextLevel.min_score;
      if (this.nextLevelPercentage > 100) {
        this.nextLevelPercentage = 100;
      }
    } else {
      this.nextLevelPercentage = 100;
    }
  }

}
