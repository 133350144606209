import { BusinessBranch } from './business_branch.model';
import { PermissionGroup } from './permission_group.model';

export class OperatorBranch {
  id?: number;
  role: 'admin' | 'operator';

  branch: BusinessBranch;
  branch_id: number;

  permission_group: PermissionGroup;
  permission_group_id: number;

  public constructor(init?: any) {
    Object.assign(this, init);
    if (init.permission_group) {
      this.permission_group = new PermissionGroup(init.permission_group);
    }
  }
}
