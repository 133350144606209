export class PaginationResult<T> {

    current_page: number;
    data: T[];
    first_page_url: string;
    from?: number;
    last_page: number;
    last_page_url: string;
    next_page_url?: string;
    path: string;
    per_page: number;
    prev_page_url?: string;
    to?: number;
    total: number;

    public constructor(init?: any) {
        Object.assign(this, init);
    }

    public getConfig(): any {
        return {
            itemsPerPage: this.per_page,
            currentPage: this.current_page,
            totalItems: this.total,
            lastPage: this.last_page,
        };
    }

}
