import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ProductOffer } from '../../_models/product_offer.model';

@Component({
  selector: 'puntos-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit {
  @Input() productOffers: ProductOffer[] = [];
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  constructor(private ref: NbDialogRef<ProductsListComponent>) { }

  ngOnInit() {
  }

  cancel() {
    this.ref.close(false);
  }

  accept() {
    this.ref.close(true);
  }

}
