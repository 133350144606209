import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxNumberWithCommas' })
export class NumberWithCommasPipe implements PipeTransform {

  transform(input: number): string {
    if (input === null || typeof input === 'undefined') return '-';

    const roundedValue = Math.floor(input);
    return roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}
