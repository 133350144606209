import { Business } from './business.model';
import {Read} from './read.model';
import {Product} from './product.model';
import { Client } from './client.model';

export type TagType = 'qr-fixed' | 'qr-variable' | 'email' | 'whatsapp' | 'link' | 'client' | 'affiliate-card' | 'birthday' | 'integration-add' | 'chrome-extension-add' | 'integration-sub' | 'chrome-extension-sub' | 'substraction' | 'survey';
export const tagTypesArray: TagType[] = [
  'qr-fixed',
  'qr-variable',
  'email',
  'whatsapp',
  'link',
  'client',
  'affiliate-card',
  'birthday',
  'integration-add',
  'integration-sub',
  'chrome-extension-add',
  'chrome-extension-sub',
  'substraction',
  'survey',
];

export const tagTypeName = {
  'qr-fixed': 'Código QR fijo',
  'qr-variable': 'Código QR variable',
  'email': 'E-Mail',
  'whatsapp': 'Whatsapp',
  'link': 'Vinculo',
  'client': 'Suma directa',
  'affiliate-card': 'Tarjeta',
  'birthday': 'Cumpleaños',
  'integration-add': 'Integracion Externa',
  'chrome-extension-add': 'Extension Google Chrome',
  'substraction': 'Sustracción',
  'survey': 'Encuesta',
};

export enum TagStatuses {
  Pending = 'pending',
  Active = 'active',
  Deactivated = 'deactivated',
  Expired = 'expired',
  Cancelled = 'cancelled',
}

export class Tag {
  id: number;
  business_id: number;
  type: TagType = 'qr-fixed';
  code: string;
  image: string;
  score: number = 0;
  amount: number = 0;
  description: string;
  message: string;
  reason: string;
  email: string;
  phone: string;
  affiliate_card: string;
  redirect_url: string;
  times: number;
  status: TagStatuses;
  url: string;
  time_restriction_amount?: number;
  time_restriction_unit?: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;

  extra_data: string;
  external_id: string;

  business?: Business;
  client?: Client;
  read?: Read;
  nextProductOffer?: Product;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
    }
  }

}
