<h1 id="title" class="title">Cambiar contraseña</h1>
<p class="sub-title">Configurá una nueva contraseña para tu cuenta.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>¡Upa!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>¡Buenísimo!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-password">Tu nueva contraseña</label>
    <input nbInput
           [(ngModel)]="user.password"
           #password="ngModel"
           type="password"
           id="input-password"
           name="password"
           class="first"
           placeholder="Nueva Contraseña"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        <i class="fas fa-times-circle"></i>
        Necesitamos tu nueva contraseña.
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        <i class="fas fa-times-circle"></i>
        La contraseña debe tener
        más de {{ getConfigValue('forms.validation.password.minLength') }}
        caracteres.
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirmá tu nueva contraseña</label>
    <input nbInput
           [(ngModel)]="user.confirmPassword"
           #rePass="ngModel"
           id="input-re-password"
           name="rePass"
           type="password"
           class="last"
           placeholder="Confirmar"
           fullWidth
           fieldSize="large"
           [status]="rePass.touched
               ? (rePass.invalid || password.value != rePass.value ? 'danger' : 'success')
               : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
    <ng-container *ngIf="rePass.touched">
      <p class="caption status-danger" *ngIf="rePass.invalid && rePass.errors?.required">
        <i class="fas fa-times-circle"></i>
        Necesitamos que confirmes tu contraseña.
      </p>
      <p class="caption status-danger" *ngIf="password.value != rePass.value && !rePass.errors?.required">
        <i class="fas fa-times-circle"></i>
        Las contraseñas no coinciden.
      </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          class="puntos-button filled"
          size="large"
          [disabled]="submitted || !resetPassForm.valid"
          [class.btn-pulse]="submitted">
    Cambiar contraseña
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Iniciar sesión</a></p>
  <p><a class="text-link" routerLink="../register">Registrate</a></p>
</section>
