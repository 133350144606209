import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@nebular/auth";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
export class AuthGuard {
    constructor(authService, router, location) {
        this.authService = authService;
        this.router = router;
        this.location = location;
    }
    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(map(authenticated => {
            if (!authenticated) {
                const params = {};
                const path = this.location.path();
                if (path.length && path.indexOf('auth') === -1 && path.indexOf('redirect') === -1) {
                    params.then = path;
                }
                this.router.navigate(['auth/login', params]);
                return false;
            }
            return true;
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.NbAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location)); }, token: AuthGuard, providedIn: "root" });
