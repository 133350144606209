<div>
  <p class="text-center">Crear nuevo usuario</p>

  <div class="form-group">
    <label for="inputEmail" class="label">Correo electrónico del cliente</label>
    <input
      nbInput
      fullWidth
      type="text"
      id="inputEmail"
      name="email"
      class="form-control"
      [(ngModel)]="client.user.email"
      (ngModelChange)="updateClient()"
      #inputEmail="ngModel"
      required
      email
      [class.is-invalid]="inputEmail.touched && !inputEmail.valid"
    />
  </div>

  <div class="form-group">
    <label for="inputFirstName" class="label">Nombre</label>
    <input
      nbInput
      fullWidth
      type="text"
      id="inputFirstName"
      name="dni"
      class="form-control"
      [(ngModel)]="client.user.first_name"
      (ngModelChange)="updateClient()"
    />
  </div>

  <div class="form-group">
    <label for="inputLastName" class="label">Apellido</label>
    <input
      nbInput
      fullWidth
      type="text"
      id="inputLastName"
      name="dni"
      class="form-control"
      [(ngModel)]="client.user.last_name"
      (ngModelChange)="updateClient()"
    />
  </div>

  <div class="form-group">
    <label for="inputDNI" class="label">DNI</label>
    <div>
      <input
        nbInput
        fullWidth
        type="text"
        id="inputDNI"
        name="dni"
        class="form-control"
        [(ngModel)]="client.user.dni"
        (ngModelChange)="updateClient()"
      />
    </div>
  </div>

  <div class="form-group" style="display: flex; flex-direction: column;">
    <label for="inputPhone" class="label">Teléfono</label>
    <ngx-intl-tel-input
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="false"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.Argentina"
      [maxLength]="15"
      [phoneValidation]="true"
      searchCountryPlaceholder="Buscar país"
      name="phone"
      [(ngModel)]="phone"
    >
    </ngx-intl-tel-input>
  </div>

  <div class="form-group">
    <label for="inputAffiliateCard" class="label">Tarjeta de afiliado</label>
    <div>
      <input
        nbInput
        fullWidth
        type="text"
        id="inputAffiliateCard"
        name="affiliate_card"
        class="form-control"
        [(ngModel)]="client.affiliate_card"
        (ngModelChange)="updateClient()"
      />
    </div>
  </div>

  <div class="form-group">
    <label for="inputDescription" class="label">Descripción</label>
    <div>
      <textarea
        nbInput
        fullWidth
        type="text"
        id="inputDescription"
        name="description"
        class="form-control"
        [(ngModel)]="client.user.description"
        (ngModelChange)="updateClient()"
      ></textarea>
    </div>
  </div>

  <div
    class="form-group"
    style="display: flex; justify-content: start; align-items: center; gap: 1em"
  >
    <label for="inputSendNotification" class="label" style="margin: 0"
      >Enviar notificación</label
    >
    <input
      nbCheckbox
      type="checkbox"
      id="inputSendNotification"
      name="send_notification"
      class="form-control"
      [(ngModel)]="client.user.send_notification"
      (ngModelChange)="updateClient()"
      style="width: auto"
    />
  </div>
</div>
