import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CountryISO } from 'ngx-intl-tel-input';

@Component({
  selector: 'puntos-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
})
export class PhoneFormComponent implements OnInit {
  @Input() message: string = '';
  @Output() onSetPhone = new EventEmitter<string>();

  status: string = 'basic';
  acceptButtonStatus = 'success';
  deleteButtonStatus = 'danger';

  CountryISO = CountryISO;

  phone: any;

  constructor(private ref: NbDialogRef<PhoneFormComponent>) { }


  ngOnInit() {

  }

  accept() {
    this.onSetPhone.emit(this.phone.e164Number);
    this.ref.close();
  }

  cancel() {
    this.ref.close();
  }
}
