export class ProductImage {
  id: number;
  index: number;
  image: string;
  imageSizes: any;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
      this.setImageSizes();
    }
  }

  setImage(filename: string) {
    this.image = filename;
    this.setImageSizes();
  }

  setImageSizes(): any {
    const sizes = {
      'thumb': '50x50',
      'small': '200x150',
      'standard': '400x300',
      'medium': '720x405_max',
    };

    this.imageSizes = {};

    Object.keys(sizes).forEach((size) => {
      if (typeof this.image === 'string') {
        this.imageSizes[size] = 'https://images.tiendadepuntos.com/' + sizes[size] + '/' + this.image;
      }
    });
  }

}
