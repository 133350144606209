import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CommunicationDetailComponent } from '../communication-detail/communication-detail.component';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'puntos-comunnication',
  templateUrl: './comunnication.component.html',
  styleUrls: ['./comunnication.component.scss'],
})
export class ComunnicationComponent implements OnInit {
  @Input() notifications: string[];

  messageHtml: SafeHtml;

  constructor(private ref: NbDialogRef<ComunnicationComponent>,
              private dialogService: NbDialogService) {
  }

  ngOnInit() {

  }

  goToWelcomeVideo() {
    this.dialogService.open(NotificationComponent, {
      context: {
        title: 'Bienvenido a Tienda de Puntos',
        videoURL: 'https://www.youtube.com/embed/lmWgvgbcAmI',
        message: '',
        showButton: false,
      },
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });
  }

  goToDetail(id: Number) {
    let message = '', title = '';
    switch (id) {
      case 1:
        title = '¿Qué es y para qué sirve fidelizar?';
        message = `
                  <div>
                    <p> Un programa de fidelización es una estrategia de marketing centrada en recompensar el comportamiento de compra de los clientes
                    a fin de incentivar las compras repetidas y el sentido de pertenencia con la marca. </p>

                    <ul>
                      <li> Ayuda a reducir el gasto en marketing, incrementando los márgenes de beneficios. Conseguir compras repetidas de clientes fidelizados
                      tiene un coste muy inferior a captar clientes desde cero. Además, si los clientes fidelizados nos recomiendan, estaremos captando nuevas
                      conversiones sin costo adicional. Todo ello se traduce en que los costes de marketing de la empresa disminuyen.
                      </li>

                      <li> Mejora el valor de tiempo de vida del cliente, esto es, la cantidad de beneficios que nos genera cada cliente durante el tiempo que
                      permanece con la marca.Los programas de fidelización nos permiten aumentar tanto el valor medio de cada compra como la frecuencia de las mismas.
                      </li>

                      <li> Los clientes fidelizados no acuden a las marcas de la competencia, lo que hace que reforcemos nuestro posicionamiento dentro del mercado.
                      </li>

                      <li> Contar con una base de clientes fidelizados nos aporta ingresos más estables, que a su vez podemos destinar a gastos fijos o a ampliar
                      nuestro negocio.
                      </li>

                      <li> Al crear relaciones a largo plazo con los clientes, tenemos cada vez más información sobre ellos. Esto nos coloca en una posición
                      ventajosa respecto de la competencia y hace posible lanzar acciones de marketing altamente personalizadas, lo que a su vez multiplica
                      las probabilidades de éxito
                      </li>
                    </ul>
                  </div>
        `;
        break;
      case 2:
        title = '¿Cuál es el mejor método para enviar puntos?';
        message = `
                  <div>
                    <p>
                    Cada comercio y cada rubro tiene sus particularidades. Es importante conocer a nuestros clientes para saber de qué forma van a sentirse más
                    cómodos para interactuar con tu tienda de puntos. Por ejemplo, en comercios del rubro tecnológico suele ser más frecuente la suma de puntos a
                    través de la lectura de QR, pero en otros rubros es muy útil enviar los puntos a través de Whatsapp ya que es una vía de comunicación muy utilizada.

                    Por otro lado, el Plan Premium te permite asignar puntos a los clientes directamente a través de sus datos de usuario o a través de un número
                    de tarjeta que vos le brindes. De esta manera le facilitas el proceso a tu cliente ya que con solo brindarte su mail o número de tarjeta ya puede sumar
                    sus puntos sin tener que realizar ninguna otra acción.

                    Te recomendamos probar las distintas opciones que brinda Tienda de Puntos y elegir la que les resulte más cómoda a tus clientes y a vos.
                    </p>
                  </div>
        `;
        break;
      case 3:
        title = '¿Por qué motivos envío puntos?';
        message = `
                  <div>
                    <p>
                    Podés enviar puntos cuando vos quieras por el motivo que quieras. La razón más frecuente para el envío de puntos es luego de una venta,
                    enviando la cantidad correspondiente al monto abonado por tu cliente. Pero también podés enviar puntos de forma automática para los cumpleaños
                    de tus clientes, o podrías realizar un sorteo de puntos por una fecha especial.

                    Tienda de Puntos es una herramienta de marketing muy versátil, y por eso es importante que la utilices según la estrategia que vos definas.
                    </p>
                  </div>
        `;
        break;
      default:
        message = '¡Bienvenido a Tienda de Puntos!';
        break;
    }

    this.dialogService.open(CommunicationDetailComponent, {
      context: {
        title: title,
        message: message,
      },
      hasBackdrop: false,
      autoFocus: false,
    });
  }

 close() {
   this.ref.close();
  }

}
