import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Product } from '../_models/product.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Category, CategoryChange } from '../_models/category.model';
import { Business } from '../_models/business.model';
import { ProductBusiness } from '../_models/product_business.model';
import { ProductCoupon } from '../_models/product_coupon.model';
import { ProductOffer } from '../_models/product_offer.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public reloadCategories: EventEmitter<Category[]> = new EventEmitter<
    Category[]
  >();
  constructor(private http: HttpClient) {}

  one(id?: number): Product {
    const product = new Product();
    if (id) {
      product.id = id;
    }
    return product;
  }

  get(product: Product) {
    return this.http.get('products/' + product.id);
  }

  getProductOffer(productOfferId: number): Observable<ProductOffer> {
    return this.http.get('products/offer/' + productOfferId).pipe(
      map((response: any) => {
        return new ProductOffer(response);
      }),
    );
  }

  getAllProductsOffers(): Observable<ProductOffer[]> {
    return this.http.get(`products/offers`).pipe(
      map((response: any) => {
        return response.map(function (product) {
          return new ProductOffer(product);
        });
      }),
    );
  }

  delete(product: Product, business: Business) {
    return this.http.delete(`products/${product.id}/business/${business.id}`);
  }

  getMarketplacetPaginationResult(params?) {
    return this.http.get('products/marketplace', { params: params }).pipe(
      map((response: any) => {
        response.data = response.data.map((product) => {
          return new Product(product);
        });

        return new PaginationResult<Product>(response);
      }),
    );
  }

  getPaginationResult(params?) {
    return this.http.get('products', { params: params }).pipe(
      map((response: any) => {
        const products_pagination_result = response;
        products_pagination_result.data = response.data.map((product) => {
          return new Product(product);
        });
        return new PaginationResult<Product>(products_pagination_result);
      }),
    );
  }

  getShopPaginationResult(params?) {
    return this.http.get('products/shop', { params: params }).pipe(
      map((response: any) => {
        const products_pagination_result = response;
        products_pagination_result.data = response.data.map((product) => {
          return new Product(product);
        });
        return new PaginationResult<Product>(products_pagination_result);
      }),
    );
  }

  all(params?) {
    return this.http.get('products', { params: params }).pipe(
      map((response: any) => {
        return response.map((product) => new Product(product));
      }),
    );
  }

  save(product: Product, categoryChanges: CategoryChange[]) {
    const method = product.id ? 'put' : 'post';
    let url = 'products';

    if (method === 'put') {
      url += '/' + product.id;
    }

    return this.http.request(method, url, {
      body: { ...product, categoryChanges },
    });
  }

  addToPlan(productBusiness: ProductBusiness) {
    return this.http.request('post', 'products/shop', {
      body: productBusiness,
    });
  }

  removeToPlan(product: Product, business: Business) {
    return this.http.delete(
      `products/shop/${product.id}/business/${business.id}`,
    );
  }

  patch(product, params) {
    return this.http.patch('products/' + product.id, params);
  }

  activateProductBusiness(product, business, active) {
    return this.http.patch(
      `products/shop/${product.id}/business/${business.id}/activate/${active}`,
      null,
    );
  }

  patchProductBusiness(productId, businessId, params) {
    return this.http.patch(
      `products/shop/${productId}/business/${businessId}`,
      params,
    );
  }

  search = (params) => {
    return this.http.get('products/search', { params: params });
  }

  getAllCategories() {
    return this.http.get('products/categories');
  }

  uploadCoupons(id: number, file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`products/loadsheet/${id}/coupons`, formData);
  }

  getProductCoupon(coupon: ProductCoupon) {
    return this.http.get(`products/coupon/${coupon.id}`);
  }
}
