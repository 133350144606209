<nb-card [status]="status">
  <nb-card-header *ngIf="showHeader" style="display: flex; justify-content: space-between; background-color: white;">
    <div style="color: black;" *ngIf="title != 'none'">{{ title }}</div>
    <button *ngIf="closeButton" (click)="close()" class="close close-button">
      <nb-icon icon="Close_MD" class="icon-close"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="messageHtml" [innerHtml]="messageHtml" style="max-width: 450px;" ></div>
    <img *ngIf="image" src="{{ image }}" alt="" style="display: flex; margin: auto;">
    <iframe *ngIf="videoURL" style="border: 0; max-height: 400px; max-width: 450px;" [src]="safeURL" allowfullscreen></iframe>
    <button nbButton fullWidth (click)="accept()" class="puntos-button filled mt-3">{{ buttonText }}</button>
  </nb-card-body>
</nb-card>
