import { User } from './user.model';
import { Business } from './business.model';
import { Tag } from './tag.model';

export class Read {
  id: number;
  user_id: number;
  business_id: number;
  tag_id: string;
  score: number;
  amount: number;
  created_at: string;
  updated_at: string;
  type = 'read';

  current_score: number;
  status: string;
  reason: string;

  user?: User;
  business?: Business;
  tag?: Tag;

  public constructor(init?: any) {
    Object.assign(this, init);

    if (this.user) {
      this.user = new User(this.user);
    }
  }
}
