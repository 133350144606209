import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'puntos-tdp-pagination',
  templateUrl: './tdp-pagination.component.html',
  styleUrls: ['./tdp-pagination.component.scss'],
})
export class TdpPaginationComponent implements OnInit {
  @Input() id: string;
  @Input() maxSize: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageBoundsCorrection = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }


}
