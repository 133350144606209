import { User } from './user.model';
import { Business } from './business.model';
import { OperatorBranch } from './operator_branch.model';

export class Operator {
  id?: number;
  role: 'admin' | 'operator';

  business: Business;
  business_id: number;

  user: User;
  user_id: number;

  operator_branches: OperatorBranch[] = [];
  activeBranch: OperatorBranch = null;
  public constructor(init?: any) {
    Object.assign(this, init);
    if (this.operator_branches.length > 0) {
      this.activeBranch = new OperatorBranch(this.operator_branches[0]);
    }
  }

  get permissions() {
    return this.activeBranch && this.activeBranch.permission_group && this.activeBranch.permission_group.permissions ? this.activeBranch.permission_group.permissions : null;
  }

  canAccessTo(key: string) {
    const variables = {
      exchange: !this.permissions ? true : !!this.permissions.exchange.can_view,
      operate: !this.permissions ? true : !!this.permissions.operate.can_view,
      clients: !this.permissions ? true : !!this.permissions.clients.can_view,
      history: !this.permissions ? true : !!this.permissions.history.can_view,
      history_provider: !this.permissions ? true : !!this.permissions.history_provider.can_view,
      products: !this.permissions ? true : !!this.permissions.products.can_view,
      products_shop: !this.permissions ? true : !!this.permissions.products_shop.can_view,
      statistics: !this.permissions ? true : !!this.permissions.statistics.can_view,
      validate: !this.permissions ? true : !!this.permissions.validate.can_view,
      integrations: !this.permissions ? true : !!this.permissions.integrations.can_view,
      levels: !this.permissions ? true : !!this.permissions.levels.can_view,
      surveys: !this.permissions ? true : !!this.permissions.surveys.can_view,
    };

    return variables[key];

  }

  get isAdmin() {
    return this.role === 'admin';
  }
}
