/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./callback.component";
import * as i6 from "@angular/router";
import * as i7 from "@nebular/auth";
var styles_AuthCallbackComponent = [i0.styles];
var RenderType_AuthCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthCallbackComponent, data: {} });
export { RenderType_AuthCallbackComponent as RenderType_AuthCallbackComponent };
export function View_AuthCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nb-icon", [["class", "fa-3x"], ["pack", "fab"]], [[8, "innerHTML", 1], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null]], null, null, i2.View_NbIconComponent_0, i2.RenderType_NbIconComponent)), i1.ɵdid(2, 638976, null, 0, i3.NbIconComponent, [i4.DomSanitizer, i3.NbIconLibraries, i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], pack: [1, "pack"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.social; var currVal_8 = "fab"; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "text-center ", _co.social, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).html; var currVal_2 = i1.ɵnov(_v, 2).primary; var currVal_3 = i1.ɵnov(_v, 2).info; var currVal_4 = i1.ɵnov(_v, 2).success; var currVal_5 = i1.ɵnov(_v, 2).warning; var currVal_6 = i1.ɵnov(_v, 2).danger; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AuthCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "puntos-callback", [], null, null, null, View_AuthCallbackComponent_0, RenderType_AuthCallbackComponent)), i1.ɵdid(1, 245760, null, 0, i5.AuthCallbackComponent, [i6.ActivatedRoute, i7.NbAuthService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthCallbackComponentNgFactory = i1.ɵccf("puntos-callback", i5.AuthCallbackComponent, View_AuthCallbackComponent_Host_0, {}, {}, []);
export { AuthCallbackComponentNgFactory as AuthCallbackComponentNgFactory };
