import { PermissionBase } from './permission_base.model';

export class PermissionProductsShop extends PermissionBase {
    can_add: boolean = false;
    can_edit: boolean = false;
    can_remove: boolean = false;
    can_change_status: boolean = false;

    constructor() {
        super();
    }
}
