import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Plan } from '../_models/plan.model';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  pagination_cache: Plan[] = [];

  constructor(private http: HttpClient) {
  }

  getPaginationResult(params?) {
    return this.http
      .get('business-plans', { params: params })
      .pipe(map((response: any) => {
        const pagination_result = response;
        pagination_result.data = pagination_result.data.map((plan) => {
          return new Plan(plan);
        });
        this.pagination_cache = pagination_result.data;
        return new PaginationResult<Plan>(pagination_result);
      }));
  }

  get(id?) {
    // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
    // if (user) return of(user);
    return this.http.get(`business-plans/${id}`)
      .pipe(map((response) => new Plan(response)));
  }

  save(plan: Plan) {
    const method = plan.id ? 'put' : 'post';
    let url = 'business-plans';

    if (method === 'put') {
      url += '/' + plan.id;
    }

    return this.http.request(method, url, { body: plan });
  }

  search = (query) => {
    const params: any = { query };
    return this.http.get('business-plans/search', { params: params } );
  }

  delete(planSourceId: number, planDestinationId: number) {
    return this.http.request('delete', `business-plans/${planSourceId}`, { body: { destination_plan_id: planDestinationId } });
  }

}
