import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'puntos-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  @Input() status: string;
  @Input() title: string;
  @Input() message: string = null;
  @Input() image: string;
  @Input() videoURL: string;
  @Input() buttonText = 'OK';
  @Input() buttonColor = null;
  @Input() buttonStatus = 'primary';
  @Input() showButton: boolean = true;
  @Input() closeButton: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() redirectURL: string = null;

  @Output() onAccept: EventEmitter<any> = new EventEmitter<any>();

  messageHtml: SafeHtml = null;
  safeURL: SafeResourceUrl;

  constructor(private ref: NbDialogRef<NotificationComponent>,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {
    if (this.message) this.messageHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
    this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
  }

  accept() {
    this.onAccept.emit(true);

    if (this.redirectURL !== null) {
      window.open(this.redirectURL, '_blank');
    }
    this.ref.close();
  }

  close() {
    this.ref.close();
  }
}
