import { PermissionBase } from './permission_base.model';

export class PermissionClients extends PermissionBase {
    can_delete: boolean = false;
    can_edit: boolean = false;
    can_substraction_points: boolean = false;
    can_send_reactivate_account_mail: boolean = false;
    can_associate_email: boolean = false;
    can_create_link_regenerate_password: boolean = false;
    can_change_status: boolean = false;
    can_change_role: boolean = false;

    constructor() {
        super();
    }
}
