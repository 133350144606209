<nb-card [status]="status">
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body class="body">
    <div [innerHtml]="messageHtml"></div>
    <div class="button-containers">
      <input nbInput type="file" (change)="handleFileInput($event.target.files)">
      <button nbButton class="float-right mr-2 download-button" (click)="downloadTemplate()" nbTooltip="{{exampleFileTooltip}}"
        nbTooltipStatus="primary" status="primary">
        <nb-icon icon="Download" size="large"></nb-icon>
      </button>
    </div>
    <label *ngIf="showSendNotificationButton"><input type="checkbox" [(ngModel)]="sendNotification"> {{ sendNotificationText }} </label>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()" [status]="cancelButtonStatus" class="float-left">{{ cancelButtonText }}</button>
    <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">{{ acceptButtonText
      }}</button>
  </nb-card-footer>
</nb-card>