import { Component, Input, OnInit } from '@angular/core';
import { Business } from '../../_models/business.model';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-businesses-list',
  templateUrl: './businesses-list.component.html',
  styleUrls: ['./businesses-list.component.scss'],
})
export class BusinessesListComponent implements OnInit {
  @Input() businesses: Business[] = [];
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  constructor(private ref: NbDialogRef<BusinessesListComponent>) { }

  ngOnInit() { }

  close() {
    this.ref.close(false);
  }
}
