import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tag } from '../_models/tag.model';
import { DownloadService } from '../shared/download/download.service';
import { Read } from '../_models/read.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { ProductOffer } from '../_models/product_offer.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/download/download.service";
export class TagService {
    constructor(http, downloadService) {
        this.http = http;
        this.downloadService = downloadService;
    }
    one(id) {
        const tag = new Tag();
        if (id) {
            tag.id = id;
        }
        return tag;
    }
    get(tag) {
        return this.http.get('tags/' + tag.id)
            .pipe(map((response) => new Tag(response)));
    }
    getPaginationResult(params) {
        return this.http
            .get('tags', { params: params })
            .pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((tag) => {
                return new Tag(tag);
            });
            return new PaginationResult(pagination_result);
        }));
    }
    all(params) {
        return this.http.get('tags', { params })
            .pipe(map((response) => {
            return response.map((tag) => new Tag(tag));
        }));
    }
    save(tag) {
        const method = tag.id ? 'put' : 'post';
        let url = 'tags';
        if (method === 'put') {
            url += '/' + tag.id;
            return this.http.put(url, tag);
        }
        return this.http.post(url, tag);
    }
    read(id) {
        const url = `read/${id}`;
        return this.http
            .get(url, { headers: { 'Accept': 'application/json' } })
            .pipe(map((r) => ({
            read: new Read(r.read),
            nextProductOffer: r.nextProductOffer ? new ProductOffer(r.nextProductOffer) : null,
        })));
    }
    delete(tag) {
        const url = `tags/${tag.id}`;
        return this.http.delete(url);
    }
    download(tag) {
        return this.http
            .get('tags/' + tag.id + '/print.pdf', { responseType: 'blob' })
            .subscribe((response) => {
            this.downloadService.download('tdp-qr-' + tag.code + '.pdf', response, '');
        });
    }
    substract(data) {
        const method = 'post';
        const url = 'tags/substraction';
        return this.http.request(method, url, { body: data });
    }
}
TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DownloadService)); }, token: TagService, providedIn: "root" });
