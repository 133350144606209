import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { Plan } from '../_models/plan.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PlanService {
    constructor(http) {
        this.http = http;
        this.pagination_cache = [];
        this.search = (query) => {
            const params = { query };
            return this.http.get('business-plans/search', { params: params });
        };
    }
    getPaginationResult(params) {
        return this.http
            .get('business-plans', { params: params })
            .pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((plan) => {
                return new Plan(plan);
            });
            this.pagination_cache = pagination_result.data;
            return new PaginationResult(pagination_result);
        }));
    }
    get(id) {
        // const user = this.pagination_cache.find(u => u.id.toString() === id && u.business_id.toString() ===  businessId);
        // if (user) return of(user);
        return this.http.get(`business-plans/${id}`)
            .pipe(map((response) => new Plan(response)));
    }
    save(plan) {
        const method = plan.id ? 'put' : 'post';
        let url = 'business-plans';
        if (method === 'put') {
            url += '/' + plan.id;
        }
        return this.http.request(method, url, { body: plan });
    }
    delete(planSourceId, planDestinationId) {
        return this.http.request('delete', `business-plans/${planSourceId}`, { body: { destination_plan_id: planDestinationId } });
    }
}
PlanService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanService_Factory() { return new PlanService(i0.ɵɵinject(i1.HttpClient)); }, token: PlanService, providedIn: "root" });
