import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Integration } from '../_models/integrations/integration.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(private http: HttpClient) { }

  save(integration) {
    const method = integration.id ? 'put' : 'post';
    let url = `integrations`;

    if (method === 'put') {
      url += `/${integration.id}`;
    }

    return this.http.request(method, url, { body: integration });
  }

  getAll(params) {
    return this.http.get(`integrations`, { params })
    .pipe(map((response: any) => {
      const pagination_result = response;
        pagination_result.data = pagination_result.data.map((integration) => {
          return new Integration(integration);
        });
        return new PaginationResult<Integration>(pagination_result);
      }));
  }

  get(integrationId) {
    return this.http.get(`integrations/${integrationId}`);
  }

  delete(integration: Integration) {
    return this.http.delete(`integrations/${integration.id}`);
  }

  changeStatus(integration: Integration, enabled: boolean) {
    return this.http.put(`integrations/${integration.id}/status/${enabled}`, {});
  }

  authDragonFish(data: any) {
    return this.http.post(`integrations/dragonfish/auth`, data);
  }

  healthCheck(id: number) {
    return this.http.get(`integrations/${id}/health`);
  }

  authFudo(data: any) {
    return this.http.post(`integrations/fudo/auth`, data);
  }

}
