import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  @Input() status: string;
  @Input() title: string;
  @Input() message: string;
  @Input() acceptButtonText = 'OK';
  @Input() cancelButtonText = 'Cancelar';

  @Output() onAccept: EventEmitter<any> = new EventEmitter<any>();

  messageHtml: SafeHtml = null;
  titleHtml: SafeHtml = null;

  constructor(
    private ref: NbDialogRef<ConfirmComponent>,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (this.message)
      this.messageHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
    if (this.title)
      this.titleHtml = this.domSanitizer.bypassSecurityTrustHtml(this.title);
  }

  cancel() {
    this.ref.close(false);
  }

  accept() {
    this.onAccept.emit(true);
    this.ref.close(true);
  }
}
