import { Business } from './business.model';
import { Level } from './level.model';
import { Product } from './product.model';

export class ProductBusiness {
  id: number;

  product_id: number;
  product: Product;

  business_id: number;
  business: Business;

  level_id: number;
  level: Level;

  score: number;
  description: string;
  reclaimed: number;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
    }
  }

}
