import * as moment from 'moment';
import { ClientStatus } from './_enums/client_status';
import { User } from './user.model';
import { Level } from './level.model';
import { ProductOffer } from './product_offer.model';

export class Client {
  id: number;
  email_verified_at?: moment.Moment;
  description: string;
  birthday: moment.Moment;

  business_id: number;
  score: number;
  total_score: number;
  reads_counter: number;
  purchases_counter: number;

  available_buys: number;
  total_buys: number;

  transaction_score: number;
  transaction_type: number;
  transaction_date: moment.Moment;

  last_exchange: Date;
  last_operation: Date;
  last_transaction: Date;

  affiliate_card: string = '';

  status: ClientStatus = ClientStatus.Active;

  level_id: number;
  level: Level;

  nextLevel: Level;
  nextProductOffer: ProductOffer;

  user_id: number;
  user: User;

  public get Fullname() {
    if (!this.user) return '';
    return `${this.user.first_name} ${this.user.last_name}`;
  }


  public constructor(init?: any) {
    Object.assign(this, init);

    if (this.transaction_date) {
      this.transaction_date = moment(this.transaction_date);
    }

    if (init && init.level) {
      this.level = new Level(init.level);
    }

    if (init && init.user) {
      this.user = new User(init.user);
    } else {
      this.user = new User();
    }
  }

  showDescription = () => {
    if (!this.user) {
      return '';
    }
    const firstName = this.user.first_name ? this.user.first_name : '';
    const email = this.user.email ? this.user.email : '';
    const dni = this.user.dni ? this.user.dni : '';
    const lastName = this.user.last_name ? this.user.last_name : '';
    return `${firstName} ${lastName} ${email} ${dni}`;
  }
}
