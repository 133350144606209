export class Category {
  id?: number;
  name: string;
  business_id?: number;
  product_id?: number;
  selected?: Boolean = false;

  public static parseString(str: string): Category[] {
    if (str.length !== 0) {
      return str.split(',').map(cat => {
        return {name: cat, selected: false};
      });
    }
    return [];
  }

  public static toListString(categories: Category[]): string {
    let name = '';
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].selected) {
        name += `${categories[i].name},`;
      }
    }
    if (name[name.length - 1] === ',') {
      name = name.slice(0, -1);
    }
    return name;
  }
}

export enum CategoryChangeType { Add, Remove }
export class CategoryChange {
  type: CategoryChangeType;
  id?: number;
  name: string;

  static fromCategory(category: Category, type: CategoryChangeType): CategoryChange {
    if (category.id) {
      return <CategoryChange> {type, id: category.id, name: category.name};
    }
    return <CategoryChange> {type, name: category.name};
  }
}
