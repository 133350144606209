import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationConfigDragonFish } from '../../_models/integrations/integration_dragonfish.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { IntegrationService } from '../../_services/integration.service';

@Component({
  selector: 'puntos-dragon-fish-auth',
  templateUrl: './dragon-fish-auth.component.html',
  styleUrls: ['./dragon-fish-auth.component.scss'],
})
export class DragonFishAuthComponent implements OnInit {
  @Input() integration: IntegrationConfigDragonFish;
  @Output() onSetIntegrationType = new EventEmitter<IntegrationConfigDragonFish>();

  status: string = 'basic';
  acceptButtonStatus = 'success';
  deleteButtonStatus = 'danger';

  form: FormGroup;
  submitted = false;
  constructor(
    private ref: NbDialogRef<DragonFishAuthComponent>,
    private formBuilder: FormBuilder,
    private integrationService: IntegrationService,
    private toastrService: NbToastrService,
  ) {
    this.form = this.formBuilder.group({
      api_key: ['', [<any> Validators.required]],
      user: ['', [<any> Validators.required]],
      password: ['', [<any> Validators.required]],
    });
  }

  ngOnInit() {
  }

  accept() {
    const data = {
      url: this.integration.url,
      client_id: this.integration.client_id,
      api_key: this.form.get('api_key').value,
      user: this.form.get('user').value,
      password: this.form.get('password').value,
    };

    this.integrationService.authDragonFish(data).subscribe((response: any) => {
      if (response.success) {
        this.integration.access_token = response.access_token;
        this.integration.refresh_token = response.refresh_token;
        this.toastrService.success('Se obtuvieron los tokens exitosamente!.', '¡Bien!');
        this.onSetIntegrationType.emit(this.integration);
        this.ref.close(true);
      } else {
        this.toastrService.danger('Credenciales inválidas', '¡Upa!');
      }

    });

  }

  cancel() {
    this.ref.close(true);
  }
}
