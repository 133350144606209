<form [formGroup]="form" (submit)="save()" name="form" novalidate>
  <nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      
      <div class="row form-group">
        <label for="inputScore" class="label col-sm-3 col-form-label">Puntos para restar</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="number" id="inputScore" name="score" class="form-control"
            formControlName="score" pattern="[0-9]*" inputmode="numeric">
        </div>
      </div>
      
    </nb-card-body>
    <nb-card-footer>
      <a href="" (click)="cancel(); false" class="float-left" style="padding: 0.6875rem 1.125rem">{{ cancelButtonText }}</a>
      <button nbButton [status]="acceptButtonStatus" class="float-right"
        [disabled]="!form.valid || submitted">{{
        acceptButtonText }}</button>
    </nb-card-footer>
  </nb-card>
</form>
