import { Business } from './business.model';

export class Plan {
    id: number;
    name: string;
    public_name: string;
    description: string;
    price: number;
    duration: string;
    public_duration: string;
    enabled: boolean = false;
    public: boolean = false;
    default: boolean = false;

    max_products: number;
    max_products_public: number;
    use_business_brand_in_emails: boolean = false;
    money_commission: number;

    enable_control_stock: boolean = false;

    allow_categories: boolean = false;
    allow_exchange_products: boolean = false;
    allow_exchange_money: boolean = false;

    allow_operate_points_by_qr: boolean = false;
    allow_operate_points_by_email: boolean = false;
    allow_operate_points_by_whatsapp: boolean = false;
    allow_operate_points_by_link: boolean = false;
    allow_operate_points_by_affiliate_card: boolean = false;
    allow_operate_points_by_client: boolean = false;

    allow_operators: boolean = false;
    allow_permissions_groups: boolean = false;
    allow_integrations: boolean = false;

    allow_provider_mode: boolean = false;
    hide_products_shop: boolean = false;

    allow_levels: boolean = false;
    keep_levels: boolean = false;

    allow_surveys: boolean = false;

    allow_marketplace: boolean = false;
    allow_exchange_limits: boolean = false;

    businesses: Business[] = [];

    public constructor(init?: any) {
        Object.assign(this, init);
    }

    get canOperatePoints() {
        return this.allow_operate_points_by_affiliate_card ||
            this.allow_operate_points_by_client ||
            this.allow_operate_points_by_email ||
            this.allow_operate_points_by_link ||
            this.allow_operate_points_by_qr ||
            this.allow_operate_points_by_whatsapp;
    }



    get canExchangePoints() {
        return this.allow_exchange_money || this.allow_exchange_products;
    }

}
