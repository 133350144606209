import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-communication-detail',
  templateUrl: './communication-detail.component.html',
  styleUrls: ['./communication-detail.component.scss'],
})
export class CommunicationDetailComponent implements OnInit {

  @Input() message: string;
  @Input() title: string;

  messageHtml: SafeHtml;

  constructor(private ref: NbDialogRef<CommunicationDetailComponent>,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.messageHtml = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  close() {
    this.ref.close();
  }

}
