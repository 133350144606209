<nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <table *ngIf="branches?.length" class="table table-hover table-sm">
            <tr>
                <th class="name">Nombre</th>
                <th class="operators">Operadores</th>
                <!--<th class="enabled">Habilitado</th>-->
            </tr>
            <tr *ngFor="let branch of branches">
                <td class="name text-center">{{ branch?.name }}</td>
                <td class="operators text-center">{{ branch?.operators?.length }}</td>
                <!--<td *ngIf="branch?.enabled" class="enabled text-center">Si</td>
                <td *ngIf="!branch?.enabled" class="enabled text-center">No</td>-->
            </tr>
        </table>
        <nb-alert *ngIf="!branches?.length" status="info">
          El operador no se encuentra asignado a ninguna sucursal.
        </nb-alert>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="accept()" [status]="acceptButtonStatus" class="float-right">Cerrar</button>
    </nb-card-footer>
</nb-card>