import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { NbRoleProvider } from '@nebular/security';
import { UserService } from './_services/user.service';
import { User } from './_models/user.model';

@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(private userService: UserService) {
  }

  getRole(): Observable<string> {

    return this.userService
      .current()
      .pipe(map((user: User) => {
        return user.role;
      }));

  }
}
