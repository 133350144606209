import { ErrorHandler } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { registerLocaleData } from '@angular/common';
import localeAR from '@angular/common/locales/es-AR';
localeAR[localeAR.length - 5][2] = '¤#,##0.00'; // Remove space between symbol and number
localeAR[localeAR.length - 2]['USD'] = ['$', '$']; // Change default symbol
registerLocaleData(localeAR);
Sentry.init({
    dsn: 'https://298d2e36233048fe841f7d57dc314aeb@sentry.io/1848225',
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        // console.error(error);
        Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });
    }
}
const ɵ0 = function (req) {
    return req.url === '/api/auth/refresh-token';
};
export class AppModule {
}
export { ɵ0 };
