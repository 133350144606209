import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Business } from '../../_models/business.model';
import { User } from '../../_models/user.model';
import { PermissionProductsShop } from '../../_models/permission/permission_products_shop.model';
import { NbDialogService } from '@nebular/theme';
import { NotificationComponent } from '../../_dialogs/notification/notification.component';
import { ProductOffer } from '../../_models/product_offer.model';
import { Operator } from '../../_models/operator.model';

@Component({
  selector: 'puntos-product-cluster',
  templateUrl: './product-cluster.component.html',
  styleUrls: ['./product-cluster.component.scss'],
})
export class ProductClusterComponent implements OnInit {
  @Input() productOffer: ProductOffer;
  @Input() business: Business;
  @Input() user: User;
  @Input() operator: Operator;
  @Input() userScore: number = 0;
  @Input() size: 'small'|'medium'|'large' = 'medium';
  @Input() hideCTA: boolean = false;
  @Input() showDescription: boolean = false;
  @Input() isProductsShop: boolean = false;

  permissions: PermissionProductsShop = null;

  @Output() onAddToPlan = new EventEmitter<number>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  loading = false;
  icon = faGlobe;

  primaryColor: string;
  secondaryColor: string;

  constructor(private dialogService: NbDialogService) { }

  ngOnInit() {
    if (this.operator) {
      this.permissions = this.operator.permissions.products_shop;
    }
    this.primaryColor = this.productOffer.level ? this.productOffer.level.primary_color : document.documentElement.style.getPropertyValue('--primary-color');
    this.secondaryColor = this.productOffer.level ? this.productOffer.level.secondary_color : document.documentElement.style.getPropertyValue('--secondary-color');
  }

  addToPlan() {
    this.onAddToPlan.emit(this.productOffer.product.id);
  }

  edit() {
    this.onEdit.emit(this.productOffer);
  }

  delete() {
    this.onDelete.emit(this.productOffer);
  }

  showDescriptionPopup() {
    this.dialogService.open(NotificationComponent, {
      context: {
        status: 'white',
        message: this.productOffer.product.description,
        title: this.productOffer.product.name,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

}
