<form [formGroup]="form" (submit)="save()" name="form" novalidate>
  <nb-card [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div class="row form-group" *ngIf="askName">
        <label for="inputFirstName" class="label col-sm-3 col-form-label">Nombre</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="text" id="inputFirstName" name="first_name" class="form-control"
            formControlName="first_name">
        </div>
      </div>
      <div class="row form-group" *ngIf="askName">
        <label for="inputLastName" class="label col-sm-3 col-form-label">Apellido</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="text" id="inputLastName" name="last_name" class="form-control"
            formControlName="last_name">
        </div>
      </div>
      <div class="row form-group">
        <label for="inputEmail" class="label col-sm-3 col-form-label">Email</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="email" id="inputEmail" name="email" class="form-control"
            formControlName="email">
        </div>
      </div>
      <div class="row form-group" *ngIf="useDniAsPassword">
        <label for="inputDni" class="label col-sm-3 col-form-label" >
          DNI
        </label>
        <div class="col-sm-9" style="display: inline-flex">
          <input nbInput fullWidth type="text" id="inputDni" class="input-dni" name="dni" class="form-control"
            formControlName="dni">
          <span class="dni-tip" nbTooltip="El dni se utilizará como contraseña del usuario." nbTooltipPlacement="bottom" nbTooltipStatus="primary">
            <fa-icon [icon]="['fas', 'question-circle']"></fa-icon>
          </span>
        </div>
      </div>
      <div class="row form-group" *ngIf="!useDniAsPassword">
        <label for="inputPassword" class="label col-sm-3 col-form-label" >
          Contraseña
        </label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="password" id="inputPassword" name="password" class="form-control"
            formControlName="password">
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <a href="" (click)="cancel(); false" class="float-left" style="padding: 0.6875rem 1.125rem">{{ cancelButtonText }}</a>
      <button nbButton [status]="acceptButtonStatus" class="float-right"
        [disabled]="!form.valid">{{
        acceptButtonText }}</button>
    </nb-card-footer>
  </nb-card>
</form>
