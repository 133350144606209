import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Category, CategoryChange, CategoryChangeType } from '../../../_models/category.model';
import { ProductService } from '../../../_services/product.service';

@Component({
  selector: 'puntos-product-category-selector',
  templateUrl: './product-category-selector.component.html',
  styleUrls: ['./product-category-selector.component.scss'],
})
export class ProductCategorySelectorComponent implements OnInit {

  @Input() displaySelectAll: boolean = true;
  @Input() categories: Category[] = [];
  @Input() collapsed: boolean = false;
  @Output() selected = new EventEmitter<CategoryChange>();
  selectAll: boolean;
  qtySelected: number;

  constructor(private _productService: ProductService) { }

  ngOnInit() {
    this.selectAll = this.displaySelectAll;
    this._productService.reloadCategories.subscribe((categories) => {
      this.selectAll = false;
    });
    this.qtySelected = this.categories.filter(c => c.selected).length;
  }

  get SelectAll() {
    return this.selectAll;
  }


  categoryClick(idx) {
    let eventResult = null;
    if (idx != null) {
      this.selectAll = false;
      this.categories[idx].selected = !this.categories[idx].selected;

      if (this.categories[idx].selected) {
        this.qtySelected++;
      } else {
        this.qtySelected--;
      }

      if (this.qtySelected === 0 && this.displaySelectAll) {
        this.selectAll = true;
      }
      eventResult = CategoryChange
      .fromCategory(this.categories[idx],
                    this.categories[idx].selected ? CategoryChangeType.Add : CategoryChangeType.Remove);
    } else {
      this.selectAll = true;
      this.qtySelected = 0;
      this.categories.forEach(c => c.selected = false);
    }
    this.selected.emit(eventResult);
  }

  isSelected(idx) {
    return this.categories[idx].selected && !this.selectAll;
  }
}
