<div class="filter">
  <nb-select
    fullWidth
    autocomplete="off"
    placeholder="{{ label }}"
    name="selectControl"
    class="filter-input"
    [class.rounded]="isRounded"
    [class.rectangle]="!isRounded"
    *ngIf="type === 'select' && !selected"
    [formControl]="selectControl"
    (selectedChange)="selectSelect($event)"
  >
    <nb-option *ngFor="let item of list" [value]="item[keywordSelect]">{{
      item.name
    }}</nb-option>
  </nb-select>

  <input
    nbInput
    fullWidth
    autocomplete="off"
    placeholder="{{ label }}"
    class="filter-input"
    [class.rounded]="isRounded"
    [class.rectangle]="!isRounded"
    *ngIf="!selected && type === 'text'"
    [formControl]="searchControl"
    (click)="onClickSearchBox()"
    (blur)="onBlurInput()"
  />

  <div class="filter-results" *ngIf="showResults">
    <nb-list *ngIf="results !== null">
      <nb-list-item
        *ngFor="let item of results"
        [ngClass]="getStyles(item)"
        (click)="selectItem(item)"
        ><p>{{ getItemDescription(item) }}</p></nb-list-item
      >
    </nb-list>
  </div>

  <div *ngIf="!!selected" class="filter-value">
    <span class="value">{{
      selected.name ? selected.name : getItemDescription(selected)
    }}</span>
    <a (click)="clear()"><nb-icon icon="Close_MD"></nb-icon></a>
  </div>
</div>
